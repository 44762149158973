@import './mixins.scss';
@import './variables.scss';
// @import './themes.scss'; // old themes & vars ... ❌ is removing ... 


/* 
    💡 this is our SCSS Index, any shared .scss files should be imported here, 
    so now we only need to @import this index and our components will recieve all files above. 
    
    ex: @import '../../css/index.scss'; 
*/

// note: global.scss & styles.scss are not imported here... 
// they are in angular.json (only need to @import if referencing a variable | mixin) 