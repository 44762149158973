
// 💡 this file contains all NEW global scss variables & theme declerations ...

// Functions used to modify $variables with Alpha channel (opacity) where needed ... 

@mixin defineColorHSL($color, $hue, $saturation, $lightness) {
    // #{$color}: unquote("hsl(#{$hue}, #{$saturation}, #{$lightness})");
    #{$color}-h: #{$hue};
    #{$color}-s: #{$saturation};
    #{$color}-l: #{$lightness};
}

@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);
    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace +
            str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }
    @return $string;
}

@function alphaSCSS($color, $opacity) {
    $color: str-replace($color, "var(");
    $color: str-replace($color, ")");
    $color-h: var(#{$color + "-h"});
    $color-s: var(#{$color + "-s"});
    $color-l: var(#{$color + "-l"});
    @return hsla($color-h, $color-s, $color-l, $opacity);
}

// ❌ not currently used ? 
@function lightnessSCSS($color, $lightnessMultiplier) {
    $color: str-replace($color, "var(");
    $color: str-replace($color, ")");
    $color-h: var(#{$color + "-h"});
    $color-s: var(#{$color + "-s"});
    $color-l: var(#{$color + "-l"});
    @return hsl($color-h, $color-s, calc(#{$color-l} * #{$lightnessMultiplier}));
}

// 🚧 new color pallet
:root {
    --vivid-sky-blue: hsl(194, 100%, 60%);
    @include defineColorHSL(--vivid-sky-blue, 194, 100%, 60%); 
    --caribbean-green: hsl(163, 84%, 43%);// [status: active]
    @include defineColorHSL(--caribbean-green, 163, 84%, 43%); 
    --rich-black-fogra: hsl(221, 70%, 8%);
    @include defineColorHSL(--rich-black-fogra, 221, 70%, 8%); 
    --charcoal: hsl(215, 12%, 28%);
    --white: hsl(0, 0%, 99%);
    --page-white: hsl(0, 0%, 95%);

    // GREYS (dark - light) 
    --grey-1: hsl(219, 14%, 20%); //Use Rarily
    @include defineColorHSL(--grey-1, 219, 14%, 20%);
    --grey-2: hsl(218, 14%, 23%); //Use Rarily
    // -- charcoal Main background
    --grey-3: hsl(217, 13%, 32%); //Main Container Color
    --grey-4: hsl(220, 15%, 43%); //use for highlghts

    // --primary-white-text: rgb(255, 255, 255); // cote testing ...
    --primary-white-text: hsl(0, 0%, 99%);
    --secondary-white-text: hsla(0, 0%, 78%, 0.8);

    // --primary-black-text: var(--rich-black-fogra);
    --primary-black-text: hsl(0, 0%, 1%);
    --secondary-black-text: hsla(221, 70%, 8%, 0.7);

    --primary-grey-text: rgb(126, 129, 136); // replaced main-nav-icons  // [status: offline]

    --blue-1: hsl(198, 84%, 36%);
    --blue-2: hsl(198, 80%, 75%);
    --new-status-red: hsl(340, 100%, 50%); // not really in use ...
    --new-status-yellow: hsl(41, 100%, 50%); // use yellow2 ... 
    --new-status-orange: hsl(27, 100%, 50%); // [status: brb]
    --warning-orange: hsl(32, 100%, 50%);
    @include defineColorHSL(--warning-orange, 32, 100%, 50%);
    --error-red: hsl(0, 100%, 60%);
    @include defineColorHSL(--error-red, 0, 100%, 60%);

    //Sepia Colors(dark - light)
    --sepia-1: hsl(36, 76%, 74%);
    @include defineColorHSL(--sepia-1, 36, 76%, 74%);
    --sepia-nav: hsl(36, 100%, 78%); //For navbar only
    --sepia-2: hsl(36, 100%, 85%);
    --sepia-3: hsl(36, 100%, 90%); //main background
    --sepia-4: hsl(36, 100%, 95%);

    //Light Mode (dark - light)
    --light-grey-1: hsl(0, 0%, 87%);
    @include defineColorHSL(--light-grey-1, 0, 0%, 87%);
    --light-grey-2: hsl(0, 0%, 91%);
    // --page-white
    //-- white

    --sepia-icon: hsl(37, 70%, 18%);
    --chat-textarea-height: 6.6rem;
    --mat-ripple-color: hsla(0, 0%, 100%, 0.1);
}

body[data-theme="theme-dark"] {
    --color-primary-text: var(--primary-white-text);
    --color-secondary-text: var(--secondary-white-text);
    @include defineColorHSL(--color-secondary-text, 0, 0%, 78%);//--secondary-white-text
    --color-contrast-text: var(--primary-black-text);
    --color-sky-blue-text: var(--vivid-sky-blue);

    --color-primary-card: var(--blue-1);
    --color-secondary-card: var(--grey-4);
    --color-disabled-card: hsl(198, 84%, 16%);

    --color-input-background: hsl(219, 8%, 36%); //replaced input background

    --color-toast-background: var(--rich-black-fogra); 
    --conference-logo-filter: brightness(167%);

    --color-page-background: var(--charcoal);
    @include defineColorHSL(--color-page-background, 215, 12%, 28%);//--charcoal
    --color-primary-container: var(--grey-2);
    @include defineColorHSL(--color-primary-container, 218, 14%, 23%);//--grey-2
    --color-secondary-container: var(--grey-3);
    //  @include defineColorHSL(--color-secondary-container, 217, 13%, 32%);//--grey-3
    --color-alternate-container: var(--grey-2); //Might be temp... cote using in file storage
    @include defineColorHSL(--color-alternate-container, 218, 14%, 23%);//--grey-2
    --color-dark-container: var(--grey-1);
    --color-mini-modal: var(--grey-4);

    --color-primary-icon: var(--primary-grey-text);
    --color-primary-navbar: var(--grey-2);
    --color-logo-navbar: var(--vivid-sky-blue);

    --sidebar-background: hsl(212, 10%, 25%);//custom
    @include defineColorHSL(--sidebar-background, 212, 10%, 25%);//custom

    --mat-ripple-color: hsla(0, 0%, 100%, 0.1);

    --mat-expansion-panel-bg: var(--color-primary-container);
    --mat-expansion-panel-bg-hov: rgba(255,255,255,0.1);

    // 💡 moved from themes.scss ... 
    --status-grey2: rgb(211, 211, 211);
    --status-yellow2: rgb(236, 218, 6);
    --red: rgb(214, 3, 3);
}

// light && reader 
body[data-theme="theme-light"],
body[data-theme="theme-reader"] {
    --color-primary-text: var(--primary-black-text);
    --color-secondary-text: var(--secondary-black-text);
    @include defineColorHSL(--color-secondary-text, 221, 70%, 8%);//secondary-black-text
    --color-contrast-text: var(--primary-white-text);
    --color-sky-blue-text: hsl(194, 100%, 40%);//vivid sky blue with 40% lightness

    --color-primary-card: var(--blue-2);
    --color-secondary-card: var(--white);
    --color-disabled-card: hsl(198, 80%, 40%);

    --color-input-background: var(--white);

    --color-toast-background: var(--white);
    --conference-logo-filter: none;

    --mat-ripple-color: hsla(221, 70%, 8%, 0.1);

    // 💡 moved from themes.scss ... 
    --status-grey2: rgb(162, 162, 162);
    --status-yellow2: rgb(208, 192, 1);
    --red: red;
}

// Reader
body[data-theme='theme-reader'] {
    // --color-page-background: var(--sepia-4);
    --color-page-background: var(--sepia-3);
    @include defineColorHSL(--color-page-background, 36, 100%, 90%);//--sepia-3
    --color-primary-container: var(--sepia-4);
    @include defineColorHSL(--color-primary-container, 36, 100%, 95%);//--sepia-4
    // --color-secondary-container: var(--sepia-3);
    --color-secondary-container: var(--sepia-2);
    // --color-alternate-container: var(--sepia-3);//Might be temp... cote using in file storage
    --color-alternate-container: var(--sepia-2);
    @include defineColorHSL(--color-alternate-container, 36, 100%, 85%);//--sepia-2
    --color-dark-container: var(--sepia-1);
    --color-mini-modal: var(--sepia-4);

    --color-primary-icon: var(--sepia-icon);
    // --color-primary-navbar: var(--sepia-2);
    --color-primary-navbar: var(--sepia-nav);
    --color-logo-navbar: var(--sepia-icon);

    --sidebar-background: hsl(30, 100%, 97%);//custom
    @include defineColorHSL(--sidebar-background, 30, 100%, 97%);//custom

    --mat-expansion-panel-bg: var(--color-primary-container);
    --mat-expansion-panel-bg-hov: rgba(255,255,255,0.4);
}

// Light
body[data-theme='theme-light'] {
    --color-page-background: var(--page-white);
    @include defineColorHSL(--color-page-background, 0, 0%, 95%);//--page-white
    --color-primary-container: var(--white);
    @include defineColorHSL(--color-primary-container, 0, 0%, 99%);//white
    --color-secondary-container: var(--light-grey-2);
    --color-alternate-container: var(--light-grey-2);
    @include defineColorHSL(--color-alternate-container, 0, 0%, 91%);//--light-grey-2
    --color-dark-container: var(--light-grey-1);
    --color-mini-modal: var(--white);

    --color-primary-icon: var(--primary-black-text);
    --color-primary-navbar: var(--white);
    --color-logo-navbar: var(--rich-black-fogra);

    --sidebar-background: hsl(0, 0%, 80%);//custom
    @include defineColorHSL(--sidebar-background, 0, 0%, 100%);//custom

    --mat-expansion-panel-bg: var(--color-secondary-container);
    --mat-expansion-panel-bg-hov: rgba(255,255,255,0.9);
}


// Global SCSS Varaibles
$vivid-sky-blue: var(--vivid-sky-blue);
$caribbean-green: var(--caribbean-green);
$rich-black-fogra: var(--rich-black-fogra);
$charcoal: var(--charcoal);

$grey-1: var(--grey-1);
$grey-2: var(--grey-2);
$grey-3: var(--grey-3);
$grey-4: var(--grey-4);


$primary-white-text: var(--primary-white-text);
$secondary-white-text: var(--secondary-white-text);

$primary-black-text: var(--primary-black-text);
$secondary-black-text: var(--secondary-black-text);

$primary-grey-text: var(--primary-grey-text);
$input-background: var(--input-background);

$blue-1: var(--blue-1);
$blue-2: var(--blue-2);

$new-status-red: var(--new-status-red);
$new-status-yellow: var(--new-status-yellow);
$new-status-orange: var(--new-status-orange);
$warning-orange: var(--warning-orange);
$error-red: var(--error-red);

// 💡 moved from themes.scss ... 
$status-grey2: var(--status-grey2);
$status-yellow2: var(--status-yellow2);
$select-bg-color: rgba(0,0,0,0.15);
$white: rgb(254, 254, 254);

// Theme SCSS Variables 
$color-primary-text: var(--color-primary-text);
$color-secondary-text: var(--color-secondary-text);
$color-contrast-text: var(--color-contrast-text); // oposite as primary ... 
$color-sky-blue-text: var(--color-sky-blue-text); 

$color-page-background: var(--color-page-background);
$color-input-background: var(--color-input-background);
$color-primary-container: var(--color-primary-container);
$color-secondary-container: var(--color-secondary-container);
$color-alternate-container: var(--color-alternate-container);
$color-dark-container: var(--color-dark-container);
$color-mini-modal: var(--color-mini-modal);

$color-primary-card: var(--color-primary-card);
$color-secondary-card: var(--color-secondary-card);
$color-disabled-card: var(--color-disabled-card);

$color-primary-icon: var(--color-primary-icon);
$color-primary-navbar: var(--color-primary-navbar);
$color-logo-navbar: var(--color-logo-navbar);

$color-toast-background: var(--color-toast-background);
$conference-logo-filter: var(--conference-logo-filter);

$color-sidebar-background: alphaSCSS(var(--sidebar-background), 0.90);
// $color-sidebar-background: alphaSCSS(var(--sidebar-background), 0.85);// what deisners gave us

$green-opaque: alphaSCSS(var(--caribbean-green), 0.8);
$red: var(--red);

$mat-ripple-color: var(--mat-ripple-color);
$mat-expansion-panel-bg: var(--mat-expansion-panel-bg);
$mat-expansion-panel-bg-hov: var(--mat-expansion-panel-bg-hov);


//media variables
$mobile-break: "phablet";
$channel-break: 900px;


// test 
$test-border: 1px dotted red;


// Z Index Heiarchy 
$z-error-overlay: 10000;
$z-toast: 9000;

$z-modal-on-modal: 8500;
$z-modal: 8000;
$z-modal-backdrop: 7999;

$z-navigation: 7000;
$z-sidebar: 6000;
