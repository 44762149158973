@charset "UTF-8";
/*
    .example-el {
        padding: 1rem 3rem;
        margin-bottom: 1rem;

        @include flex-col-center-all(); //  < 560px is flex column 

        @include mq('phablet') {

            @include flex-row-nowrap-center-all(); //  > 560px is flex row 
        }
*/
@import "~@angular/material/prebuilt-themes/pink-bluegrey.css";
@import url("../../node_modules/@fortawesome/fontawesome-pro/css/all.css");
:root {
  --vivid-sky-blue: hsl(194, 100%, 60%);
  --vivid-sky-blue-h: 194;
  --vivid-sky-blue-s: 100%;
  --vivid-sky-blue-l: 60%;
  --caribbean-green: hsl(163, 84%, 43%);
  --caribbean-green-h: 163;
  --caribbean-green-s: 84%;
  --caribbean-green-l: 43%;
  --rich-black-fogra: hsl(221, 70%, 8%);
  --rich-black-fogra-h: 221;
  --rich-black-fogra-s: 70%;
  --rich-black-fogra-l: 8%;
  --charcoal: hsl(215, 12%, 28%);
  --white: hsl(0, 0%, 99%);
  --page-white: hsl(0, 0%, 95%);
  --grey-1: hsl(219, 14%, 20%);
  --grey-1-h: 219;
  --grey-1-s: 14%;
  --grey-1-l: 20%;
  --grey-2: hsl(218, 14%, 23%);
  --grey-3: hsl(217, 13%, 32%);
  --grey-4: hsl(220, 15%, 43%);
  --primary-white-text: hsl(0, 0%, 99%);
  --secondary-white-text: hsla(0, 0%, 78%, 0.8);
  --primary-black-text: hsl(0, 0%, 1%);
  --secondary-black-text: hsla(221, 70%, 8%, 0.7);
  --primary-grey-text: rgb(126, 129, 136);
  --blue-1: hsl(198, 84%, 36%);
  --blue-2: hsl(198, 80%, 75%);
  --new-status-red: hsl(340, 100%, 50%);
  --new-status-yellow: hsl(41, 100%, 50%);
  --new-status-orange: hsl(27, 100%, 50%);
  --warning-orange: hsl(32, 100%, 50%);
  --warning-orange-h: 32;
  --warning-orange-s: 100%;
  --warning-orange-l: 50%;
  --error-red: hsl(0, 100%, 60%);
  --error-red-h: 0;
  --error-red-s: 100%;
  --error-red-l: 60%;
  --sepia-1: hsl(36, 76%, 74%);
  --sepia-1-h: 36;
  --sepia-1-s: 76%;
  --sepia-1-l: 74%;
  --sepia-nav: hsl(36, 100%, 78%);
  --sepia-2: hsl(36, 100%, 85%);
  --sepia-3: hsl(36, 100%, 90%);
  --sepia-4: hsl(36, 100%, 95%);
  --light-grey-1: hsl(0, 0%, 87%);
  --light-grey-1-h: 0;
  --light-grey-1-s: 0%;
  --light-grey-1-l: 87%;
  --light-grey-2: hsl(0, 0%, 91%);
  --sepia-icon: hsl(37, 70%, 18%);
  --chat-textarea-height: 6.6rem;
  --mat-ripple-color: hsla(0, 0%, 100%, 0.1);
}

body[data-theme=theme-dark] {
  --color-primary-text: var(--primary-white-text);
  --color-secondary-text: var(--secondary-white-text);
  --color-secondary-text-h: 0;
  --color-secondary-text-s: 0%;
  --color-secondary-text-l: 78%;
  --color-contrast-text: var(--primary-black-text);
  --color-sky-blue-text: var(--vivid-sky-blue);
  --color-primary-card: var(--blue-1);
  --color-secondary-card: var(--grey-4);
  --color-disabled-card: hsl(198, 84%, 16%);
  --color-input-background: hsl(219, 8%, 36%);
  --color-toast-background: var(--rich-black-fogra);
  --conference-logo-filter: brightness(167%);
  --color-page-background: var(--charcoal);
  --color-page-background-h: 215;
  --color-page-background-s: 12%;
  --color-page-background-l: 28%;
  --color-primary-container: var(--grey-2);
  --color-primary-container-h: 218;
  --color-primary-container-s: 14%;
  --color-primary-container-l: 23%;
  --color-secondary-container: var(--grey-3);
  --color-alternate-container: var(--grey-2);
  --color-alternate-container-h: 218;
  --color-alternate-container-s: 14%;
  --color-alternate-container-l: 23%;
  --color-dark-container: var(--grey-1);
  --color-mini-modal: var(--grey-4);
  --color-primary-icon: var(--primary-grey-text);
  --color-primary-navbar: var(--grey-2);
  --color-logo-navbar: var(--vivid-sky-blue);
  --sidebar-background: hsl(212, 10%, 25%);
  --sidebar-background-h: 212;
  --sidebar-background-s: 10%;
  --sidebar-background-l: 25%;
  --mat-ripple-color: hsla(0, 0%, 100%, 0.1);
  --mat-expansion-panel-bg: var(--color-primary-container);
  --mat-expansion-panel-bg-hov: rgba(255,255,255,0.1);
  --status-grey2: rgb(211, 211, 211);
  --status-yellow2: rgb(236, 218, 6);
  --red: rgb(214, 3, 3);
}

body[data-theme=theme-light],
body[data-theme=theme-reader] {
  --color-primary-text: var(--primary-black-text);
  --color-secondary-text: var(--secondary-black-text);
  --color-secondary-text-h: 221;
  --color-secondary-text-s: 70%;
  --color-secondary-text-l: 8%;
  --color-contrast-text: var(--primary-white-text);
  --color-sky-blue-text: hsl(194, 100%, 40%);
  --color-primary-card: var(--blue-2);
  --color-secondary-card: var(--white);
  --color-disabled-card: hsl(198, 80%, 40%);
  --color-input-background: var(--white);
  --color-toast-background: var(--white);
  --conference-logo-filter: none;
  --mat-ripple-color: hsla(221, 70%, 8%, 0.1);
  --status-grey2: rgb(162, 162, 162);
  --status-yellow2: rgb(208, 192, 1);
  --red: red;
}

body[data-theme=theme-reader] {
  --color-page-background: var(--sepia-3);
  --color-page-background-h: 36;
  --color-page-background-s: 100%;
  --color-page-background-l: 90%;
  --color-primary-container: var(--sepia-4);
  --color-primary-container-h: 36;
  --color-primary-container-s: 100%;
  --color-primary-container-l: 95%;
  --color-secondary-container: var(--sepia-2);
  --color-alternate-container: var(--sepia-2);
  --color-alternate-container-h: 36;
  --color-alternate-container-s: 100%;
  --color-alternate-container-l: 85%;
  --color-dark-container: var(--sepia-1);
  --color-mini-modal: var(--sepia-4);
  --color-primary-icon: var(--sepia-icon);
  --color-primary-navbar: var(--sepia-nav);
  --color-logo-navbar: var(--sepia-icon);
  --sidebar-background: hsl(30, 100%, 97%);
  --sidebar-background-h: 30;
  --sidebar-background-s: 100%;
  --sidebar-background-l: 97%;
  --mat-expansion-panel-bg: var(--color-primary-container);
  --mat-expansion-panel-bg-hov: rgba(255,255,255,0.4);
}

body[data-theme=theme-light] {
  --color-page-background: var(--page-white);
  --color-page-background-h: 0;
  --color-page-background-s: 0%;
  --color-page-background-l: 95%;
  --color-primary-container: var(--white);
  --color-primary-container-h: 0;
  --color-primary-container-s: 0%;
  --color-primary-container-l: 99%;
  --color-secondary-container: var(--light-grey-2);
  --color-alternate-container: var(--light-grey-2);
  --color-alternate-container-h: 0;
  --color-alternate-container-s: 0%;
  --color-alternate-container-l: 91%;
  --color-dark-container: var(--light-grey-1);
  --color-mini-modal: var(--white);
  --color-primary-icon: var(--primary-black-text);
  --color-primary-navbar: var(--white);
  --color-logo-navbar: var(--rich-black-fogra);
  --sidebar-background: hsl(0, 0%, 80%);
  --sidebar-background-h: 0;
  --sidebar-background-s: 0%;
  --sidebar-background-l: 100%;
  --mat-expansion-panel-bg: var(--color-secondary-container);
  --mat-expansion-panel-bg-hov: rgba(255,255,255,0.9);
}

/* 
    💡 this is our SCSS Index, any shared .scss files should be imported here, 
    so now we only need to @import this index and our components will recieve all files above. 

    ex: @import '../../css/index.scss'; 
*/
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

:root {
  --status-grey: var(--status-grey2);
  --status-green: var(--caribbean-green);
  --status-yellow: var(--status-yellow2);
  --status-orange: var(--new-status-orange);
  --status-red: var(--new-status-red);
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

* {
  margin: 0px;
  padding: 0px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "proxima-nova";
  outline: none;
  color: var(--color-primary-text);
  letter-spacing: 0.075rem;
}

body {
  background-color: var(--color-page-background);
  scrollbar-color: #060f22 rgba(0, 0, 0, 0.2);
}
body::-webkit-scrollbar {
  width: 1.6rem;
}
body::-webkit-scrollbar-track {
  border-radius: 0.8rem;
  background-color: rgba(0, 0, 0, 0.2);
  border: 0.2rem solid transparent;
}
body::-webkit-scrollbar-thumb {
  border-radius: 0.8rem;
  background-color: #060f22;
  border: 0.2rem solid transparent;
  background-clip: padding-box;
}

h1 {
  font-size: 4rem;
  line-height: 4rem;
}

h2 {
  font-size: 3.5rem;
  line-height: 3.5rem;
}

h3 {
  font-size: 2.5rem;
  line-height: 2.5rem;
}

h4 {
  font-size: 2rem;
  line-height: 2rem;
}
h4.text-ellipsis {
  line-height: 2.2rem;
  margin-top: -0.2rem;
}

h5 {
  font-size: 1.75rem;
  line-height: 1.75rem;
}
h5.text-ellipsis {
  line-height: 2rem;
}

h1,
h2,
h3,
h4,
h5 {
  white-space: nowrap;
}

p,
a,
td,
th,
li,
div,
span,
label,
select,
option {
  font-size: 1.5rem;
}

h1,
h2,
h3,
h4,
h5,
p,
a,
span,
label {
  color: var(--color-primary-text);
}

textarea,
input {
  border: none;
  width: 100%;
  font-size: 1.5rem;
  color: var(--color-primary-text);
}
textarea::placeholder,
input::placeholder {
  font-size: 1.5rem;
  opacity: 1;
  color: var(--color-primary-text);
}
textarea.login,
input.login {
  -webkit-appearance: revert;
}

input:not[type=checkbox] {
  -webkit-appearance: none;
}

select {
  color: var(--color-primary-text);
  cursor: pointer;
}
select.pre-select {
  color: var(--color-secondary-text);
}
select option {
  color: var(--primary-black-text);
  background-color: #fefefe;
  cursor: pointer;
}
select .hidden-value {
  display: none;
}

input[type=button],
input[type=submit] {
  width: auto;
}

input[type=button],
input[type=submit],
button {
  cursor: pointer;
  background: none;
  color: var(--color-primary-text);
  border: none;
  border-radius: 0.6rem;
  padding: 0;
  font-size: 1.5rem;
  white-space: nowrap;
}

input[type=date]::-webkit-calendar-picker-indicator {
  width: 0;
  margin: 0;
}

input:autofill {
  background: none;
  filter: none;
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--color-primary-text);
  -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
  box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: var(--color-primary-text);
}
input:-webkit-autofill.all-white-input,
input:-webkit-autofill:hover.all-white-input,
input:-webkit-autofill:focus.all-white-input,
textarea:-webkit-autofill.all-white-input,
textarea:-webkit-autofill:hover.all-white-input,
textarea:-webkit-autofill:focus.all-white-input,
select:-webkit-autofill.all-white-input,
select:-webkit-autofill:hover.all-white-input,
select:-webkit-autofill:focus.all-white-input {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.9);
}

input:autofill {
  box-shadow: 0 0 0 1000px var(--color-input-background) inset;
}
input:autofill:hover, input:autofill:focus, input:autofill:active {
  box-shadow: 0 0 0 1000px var(--color-input-background) inset;
}

.text-ellipsis {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.text-ellipsis.two-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
  word-break: break-word;
}

.display-none {
  display: none;
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center !important;
}

.secondary-text {
  color: var(--color-secondary-text);
}

.break-word {
  word-break: break-word;
}

.title-thin {
  font-size: 2.8rem;
  font-weight: 400;
}
@media only screen and (min-width: 560px) {
  .title-thin {
    font-size: 3rem;
  }
}

.page-title {
  font-size: 3rem;
}

.small-heading {
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--color-secondary-text);
}

.form-title {
  font-weight: normal;
}

.form-info {
  font-size: 1.4rem;
  color: var(--color-secondary-text);
}

.status-bar,
.status-dot,
.notification-dot {
  box-shadow: inset 0 0 0.15rem 0.015rem rgba(0, 0, 0, 0.4);
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 8000;
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  overflow: hidden auto;
  padding-top: 5rem;
  display: flex;
  width: 100vw;
}
@media only screen and (min-width: 560px) {
  .modal {
    padding-top: 0;
  }
}
.modal .modal-container {
  background: var(--color-primary-container);
  position: relative;
  width: 100%;
  min-height: 5rem;
  bottom: 0;
  padding: 2rem 1.5rem;
  border-radius: 2rem 2rem 0 0;
  box-shadow: 0rem 0rem 5rem rgba(0, 0, 0, 0.5);
  margin-top: auto;
  height: min-content;
}
@media only screen and (min-width: 560px) {
  .modal .modal-container {
    width: 64rem;
    border-radius: 2rem;
    padding: 3rem 4rem;
    margin: auto;
  }
}
.modal .modal-container .close-modal-x {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 2rem;
  height: 2.5rem;
  width: 2.5rem;
  opacity: 0.5;
}
.modal .modal-container .close-modal-x:hover {
  opacity: 1;
}
.modal .modal-container .close-modal-x:hover i {
  color: var(--color-primary-text);
}
.modal .modal-container .close-modal-x i {
  color: var(--color-secondary-text);
}

.mini-modal {
  position: absolute;
  top: 8rem;
  right: 50%;
  transform: translateX(50%);
  z-index: 8000;
  background-color: var(--color-mini-modal);
  border: 0.2rem solid var(--color-primary-icon);
  border-radius: 1.5rem;
  box-shadow: 0 1rem 2.5rem 2rem rgba(0, 0, 0, 0.25);
}
@media only screen and (min-width: 560px) {
  .mini-modal {
    right: -1rem;
    transform: translateX(0);
  }
}
.mini-modal button {
  width: 100%;
}

.hidden-backdrop-modal {
  display: flex;
  position: fixed;
  background: transparent;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 7999;
}

.user-img {
  overflow: hidden;
  border-radius: 50%;
}
.user-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.default-user-img {
  color: var(--color-secondary-text);
  font-weight: 800;
  font-size: 1.6rem;
  box-shadow: inset 0 0 0.15rem 0.015rem rgba(0, 0, 0, 0.25);
}
.default-user-img.every-first {
  background-color: var(--color-dark-container);
}
.default-user-img.every-second {
  background-color: var(--color-primary-container);
}
.default-user-img.every-third {
  background-color: var(--color-secondary-container);
}
.default-user-img.every-fourth {
  background-color: var(--color-page-background);
}

.circle {
  border-radius: 50%;
}

.spinner {
  animation: spinner infinite 0.8s linear;
}
@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.primary-button,
.secondary-button,
.cancel-button {
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 2.5rem;
  padding: 1rem 3rem;
  height: 4.2rem;
  white-space: nowrap;
  color: var(--primary-white-text);
  width: min-content;
  transition: 0.25s;
  cursor: pointer;
}
.primary-button.is-link,
.secondary-button.is-link,
.cancel-button.is-link {
  padding: 0 !important;
}
.primary-button.is-link a,
.secondary-button.is-link a,
.cancel-button.is-link a {
  padding: 1rem 3rem;
  display: block;
}
.primary-button:enabled:hover,
.secondary-button:enabled:hover,
.cancel-button:enabled:hover {
  box-shadow: inset 0 0 10px rgba(212, 216, 224, 0.2);
}
.primary-button:disabled,
.secondary-button:disabled,
.cancel-button:disabled {
  cursor: not-allowed;
  opacity: 0.75;
  filter: brightness(0.8);
}
.primary-button i,
.primary-button a,
.primary-button span,
.secondary-button i,
.secondary-button a,
.secondary-button span,
.cancel-button i,
.cancel-button a,
.cancel-button span {
  color: var(--primary-white-text);
  text-decoration: none;
}

.primary-button {
  background: var(--vivid-sky-blue);
}
.primary-button:enabled:hover {
  background: #00b0e6;
}

.secondary-button {
  background: var(--caribbean-green);
}
.secondary-button:enabled:hover {
  background: #0d9b73;
}

.cancel-button {
  background: var(--new-status-red);
}
.cancel-button:enabled:hover {
  background: #cc0044;
}

.invalid-form {
  cursor: not-allowed;
}

.outline-select,
.outline-button,
.outline-timepicker,
.outline-datepicker {
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 2.5rem;
  padding: 1rem 3rem;
  height: 4.2rem;
  white-space: nowrap;
  color: var(--color-primary-text);
  background-color: none;
  padding: 0.75rem 2.75rem;
}

.outline-button {
  border: 0.25rem var(--color-primary-text) solid;
  transition: 0.2s;
  cursor: pointer;
}
.outline-button:enabled:hover {
  background: rgba(175, 175, 175, 0.25);
}
.outline-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.outline-datepicker,
.outline-timepicker,
.outline-select {
  border: 0.25rem var(--color-secondary-text) solid;
  width: min-content;
  cursor: default;
}
.outline-datepicker > span,
.outline-timepicker > span,
.outline-select > span {
  color: var(--color-secondary-text);
  font-weight: normal;
  font-size: 1.4rem;
}
.outline-datepicker input,
.outline-datepicker select,
.outline-datepicker .mat-select,
.outline-timepicker input,
.outline-timepicker select,
.outline-timepicker .mat-select,
.outline-select input,
.outline-select select,
.outline-select .mat-select {
  border: none;
  background: none;
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
}

.outline-select {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.outline-select .mat-select .mat-select-trigger:hover .mat-select-arrow {
  color: var(--caribbean-green);
}
.outline-select .mat-select .mat-select-trigger .mat-select-value {
  max-width: 100%;
}
.outline-select .mat-select .mat-select-trigger .mat-select-value .mat-select-min-line {
  margin-left: 0.5rem;
  font-size: 1.4rem;
}
.outline-select .mat-select .mat-select-trigger .mat-select-value .mat-select-placeholder {
  background: transparent;
  font-size: 1.4rem;
  color: var(--color-primary-text);
}
.outline-select .mat-select .mat-select-trigger .mat-select-arrow {
  color: var(--color-primary-text);
}

.outline-datepicker input {
  width: auto;
}
.outline-datepicker input[matInput] {
  width: 9rem;
  min-width: 8rem;
  cursor: default;
}
.outline-datepicker mat-datepicker-toggle button {
  width: auto;
  height: auto;
  line-height: normal;
  position: relative;
}
.outline-datepicker mat-datepicker-toggle button::before {
  content: "";
  position: absolute;
  height: 4rem;
  width: 17rem;
  right: -1rem;
  top: -1rem;
}
.outline-datepicker mat-datepicker-toggle button span svg {
  width: 2rem;
  height: 2rem;
}

.mat-checkbox-checked .mat-checkbox-background {
  background-color: var(--caribbean-green) !important;
}

.mat-checkbox-frame {
  border-color: var(--color-primary-text) !important;
}

.outline-timepicker input {
  width: 6.75rem;
}

.outline-circle-checkbox,
.outline-checkbox,
.outline-radio {
  width: max-content;
  position: relative;
  border-radius: 2.5rem;
  cursor: pointer;
}
.outline-circle-checkbox:hover input ~ .input-icon, .outline-circle-checkbox:hover input ~ .input-name,
.outline-checkbox:hover input ~ .input-icon,
.outline-checkbox:hover input ~ .input-name,
.outline-radio:hover input ~ .input-icon,
.outline-radio:hover input ~ .input-name {
  background: rgba(175, 175, 175, 0.25);
}
.outline-circle-checkbox input,
.outline-checkbox input,
.outline-radio input {
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: absolute;
  padding: 0;
  margin: 0;
}
.outline-circle-checkbox input:checked ~ .input-icon, .outline-circle-checkbox input:checked ~ .input-name,
.outline-checkbox input:checked ~ .input-icon,
.outline-checkbox input:checked ~ .input-name,
.outline-radio input:checked ~ .input-icon,
.outline-radio input:checked ~ .input-name {
  background: rgba(175, 175, 175, 0.25);
  border: 0.25rem var(--color-primary-text) solid;
}
.outline-circle-checkbox input:checked ~ .input-icon:hover, .outline-circle-checkbox input:checked ~ .input-name:hover,
.outline-checkbox input:checked ~ .input-icon:hover,
.outline-checkbox input:checked ~ .input-name:hover,
.outline-radio input:checked ~ .input-icon:hover,
.outline-radio input:checked ~ .input-name:hover {
  background: none;
}
.outline-circle-checkbox .input-name,
.outline-circle-checkbox .input-icon,
.outline-checkbox .input-name,
.outline-checkbox .input-icon,
.outline-radio .input-name,
.outline-radio .input-icon {
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 2.5rem;
  padding: 1rem 3rem;
  height: 4.2rem;
  white-space: nowrap;
  border: 0.25rem var(--color-secondary-text) solid;
  color: var(--color-primary-text);
  background-color: none;
  text-align: center;
  transition: 0.2s;
}
.outline-circle-checkbox .input-name,
.outline-checkbox .input-name,
.outline-radio .input-name {
  padding: 0.75rem 2.75rem;
}
.outline-circle-checkbox .input-icon,
.outline-checkbox .input-icon,
.outline-radio .input-icon {
  padding: 0;
  width: 4.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
}

.borderless-input {
  display: block;
  width: 100%;
  border: none;
  font-weight: bold;
  padding-left: 0.4rem;
  margin-bottom: 2rem;
  line-height: 2.5rem;
  background: rgba(0, 0, 0, 0);
  font-size: 1.6rem;
  color: var(--color-primary-text);
  border-bottom: var(--color-secondary-text) solid 0.1rem;
}
.borderless-input::placeholder {
  color: var(--color-secondary-text);
  font-size: 1.6rem;
}
.borderless-input:disabled {
  opacity: 0.6;
}
.borderless-input:-webkit-autofill, .borderless-input:-webkit-autofill:hover, .borderless-input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--color-primary-text);
  caret-color: var(--color-primary-text);
}
.borderless-input.half {
  display: inline-block;
  width: calc(50% - 1rem);
}

.borderless-select {
  display: block;
  width: 100%;
  border: none;
  font-weight: bold;
  padding-left: 0.4rem;
  margin-bottom: 2rem;
  line-height: 2.5rem;
  background: rgba(0, 0, 0, 0);
  font-size: 1.6rem;
  color: var(--color-primary-text);
  border-bottom: var(--color-secondary-text) solid 0.1rem;
  padding-left: 0;
  padding-bottom: 0.05rem;
}
.borderless-select::placeholder {
  color: var(--color-secondary-text);
  font-size: 1.6rem;
}
.borderless-select:disabled {
  opacity: 0.6;
}
.borderless-select:-webkit-autofill, .borderless-select:-webkit-autofill:hover, .borderless-select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--color-primary-text);
  caret-color: var(--color-primary-text);
}
.borderless-select.half {
  display: inline-block;
  width: calc(50% - 1rem);
}
.borderless-select.channel-select .mat-select-trigger .mat-select-value .mat-select-min-line {
  margin-left: 0rem;
  font-size: 2.2rem;
}
.borderless-select .mat-select-trigger:hover .mat-select-arrow {
  color: var(--caribbean-green);
}
.borderless-select .mat-select-trigger .mat-select-value .mat-select-min-line {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}
.borderless-select .mat-select-trigger .mat-select-value .mat-select-placeholder {
  background: transparent;
  font-size: 1.6rem;
  color: var(--color-secondary-text);
}
.borderless-select .mat-select-trigger .mat-select-arrow {
  color: var(--color-primary-text);
}

.mat-select-panel-wrap .mat-select-panel .mat-option .mat-option-text i {
  width: 2.5rem;
  display: inline-flex;
  justify-content: center;
  margin-right: 1rem;
}

.borderless-input-group {
  display: flex;
  margin-bottom: 2rem;
  position: relative;
}
.borderless-input-group input {
  flex-grow: 1;
  margin-bottom: 0;
  padding-right: 1rem;
}
.borderless-input-group .no-button,
.borderless-input-group .input-button span,
.borderless-input-group .input-button i {
  min-width: 2.5rem;
  line-height: 2.5rem;
  font-weight: bold;
  display: inline-block;
  color: var(--color-primary-text);
  border-bottom: var(--color-secondary-text) solid 0.1rem;
}
.borderless-input-group .input-button:enabled:hover i,
.borderless-input-group .input-button:enabled:hover span {
  color: var(--color-secondary-text);
}
.borderless-input-group .input-button:disabled i,
.borderless-input-group .input-button:disabled span {
  color: hsla(var(--color-secondary-text-h), var(--color-secondary-text-s), var(--color-secondary-text-l), 0.5);
}
.borderless-input-group .input-button:disabled:hover {
  cursor: default;
}
.borderless-input-group .custom-select-list {
  position: absolute;
  top: 3rem;
  width: 100%;
  max-height: 10rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  transform-origin: 100% 0%;
  border-radius: 1rem 0 1rem 1rem;
  background-color: var(--color-primary-container);
  border: 0.1rem solid var(--color-secondary-container);
  z-index: 8000;
  box-shadow: -0.5rem 0.5rem 1rem 0rem rgba(0, 0, 0, 0.32);
  scrollbar-color: rgba(6, 15, 34, 0) rgba(0, 0, 0, 0);
  scrollbar-width: thin;
}
.borderless-input-group .custom-select-list a {
  text-decoration: none;
}
.borderless-input-group .custom-select-list .empty-list,
.borderless-input-group .custom-select-list .list-item-initial,
.borderless-input-group .custom-select-list .list-item {
  padding: 0.5rem 1rem;
  width: 100%;
  border-radius: 0;
  color: var(--color-secondary-text);
}
.borderless-input-group .custom-select-list .empty-list {
  font-size: 1.4rem;
}
.borderless-input-group .custom-select-list .list-item-initial,
.borderless-input-group .custom-select-list .list-item {
  cursor: pointer;
}
.borderless-input-group .custom-select-list .list-item-initial:hover,
.borderless-input-group .custom-select-list .list-item:hover {
  color: var(--color-primary-text);
  background-color: var(--color-secondary-container);
}
.borderless-input-group .custom-select-list .list-item-initial:hover i,
.borderless-input-group .custom-select-list .list-item:hover i {
  color: var(--color-primary-text);
}
.borderless-input-group .custom-select-list .list-item-initial i,
.borderless-input-group .custom-select-list .list-item i {
  text-align: center;
  margin-right: 0.5rem;
  color: var(--color-secondary-text);
  font-size: 1.2rem;
  width: 2rem;
}
.borderless-input-group .custom-select-list .list-item-initial .calendar-icon,
.borderless-input-group .custom-select-list .list-item .calendar-icon {
  font-size: 1.4rem;
}
.borderless-input-group .custom-select-list .list-item-initial .trash-icon,
.borderless-input-group .custom-select-list .list-item .trash-icon {
  font-size: 1.4rem;
}
.borderless-input-group .custom-select-list .list-item-initial .edit-icon,
.borderless-input-group .custom-select-list .list-item .edit-icon {
  text-align: right;
  padding-right: 0.2rem;
  font-size: 1.4rem;
}
.borderless-input-group .custom-select-list .list-item-initial .info-icon,
.borderless-input-group .custom-select-list .list-item .info-icon {
  font-size: 1.4rem;
}
.borderless-input-group .custom-select-list .list-item {
  display: flex;
  align-items: center;
}
.borderless-input-group .custom-select-list .list-item-initial {
  display: initial;
}
.borderless-input-group .custom-select-list .user-card {
  margin-bottom: 0;
}
.borderless-input-group .custom-select-list::-webkit-scrollbar {
  width: 0.8rem;
}
.borderless-input-group .custom-select-list::-webkit-scrollbar-track {
  border-radius: 0.4rem;
  background-color: rgba(0, 0, 0, 0);
  border: 0.1rem solid transparent;
}
.borderless-input-group .custom-select-list::-webkit-scrollbar-thumb {
  border-radius: 0.4rem;
  background-color: rgba(6, 15, 34, 0);
  border: 0.1rem solid transparent;
  background-clip: padding-box;
}
.borderless-input-group .custom-select-list:hover {
  scrollbar-color: rgba(6, 15, 34, 0.2) rgba(0, 0, 0, 0);
}
.borderless-input-group .custom-select-list:hover::-webkit-scrollbar-track {
  border-radius: 0.4rem;
  background-color: rgba(0, 0, 0, 0);
  border: 0.1rem solid transparent;
}
.borderless-input-group .custom-select-list:hover::-webkit-scrollbar-thumb {
  border-radius: 0.4rem;
  background-color: rgba(6, 15, 34, 0.2);
  border: 0.1rem solid transparent;
  background-clip: padding-box;
}
.borderless-input-group .custom-select-list:active {
  scrollbar-color: rgba(6, 15, 34, 0.4) rgba(0, 0, 0, 0);
}
.borderless-input-group .custom-select-list:active::-webkit-scrollbar-track {
  border-radius: 0.4rem;
  background-color: rgba(0, 0, 0, 0);
  border: 0.1rem solid transparent;
}
.borderless-input-group .custom-select-list:active::-webkit-scrollbar-thumb {
  border-radius: 0.4rem;
  background-color: rgba(6, 15, 34, 0.2);
  border: 0.1rem solid transparent;
  background-clip: padding-box;
}
.borderless-input-group .custom-select-list:active::-webkit-scrollbar-thumb:hover {
  background-color: rgba(6, 15, 34, 0.4);
}
.borderless-input-group .custom-select-list:active::-webkit-scrollbar-thumb:active {
  background-color: #060f22;
}
.borderless-input-group .custom-select-list.hidden {
  scrollbar-color: rgba(6, 15, 34, 0) rgba(0, 0, 0, 0) !important;
}
.borderless-input-group .custom-select-list.hidden::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0) !important;
}
.borderless-input-group .custom-select-list.hidden::-webkit-scrollbar-thumb {
  background-color: rgba(6, 15, 34, 0) !important;
}

.circle-radio {
  height: 2.5rem;
  line-height: 2.5rem;
  padding-left: 0;
  cursor: pointer;
  user-select: none;
  width: max-content;
  display: flex;
  align-items: center;
}
.circle-radio:hover input ~ .input-icon i {
  opacity: 1;
}
.circle-radio input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;
  display: inline-block;
  display: none;
}
.circle-radio input:checked ~ .input-icon {
  border: var(--color-primary-text) solid 0.1rem;
}
.circle-radio input:checked ~ .input-icon i {
  color: var(--color-primary-text);
  opacity: 1;
}
.circle-radio .input-icon {
  height: 2.25rem;
  width: 2.25rem;
  padding-left: 0.1rem;
  border-radius: 50%;
  border: var(--color-secondary-text) solid 0.1rem;
  background: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}
.circle-radio .input-icon i {
  transition: 0.2s;
  color: var(--color-secondary-text);
  font-size: 1.1rem;
  opacity: 0;
}
.circle-radio .input-name {
  padding: 0rem;
  margin: 0 0.75rem;
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
  color: var(--color-primary-text);
  font-size: 1.6rem;
}

.box-quote {
  position: relative;
  border-radius: 1rem;
  border: 0.1rem solid var(--color-primary-text);
  padding: 1rem 0.8rem 1rem 1rem;
}
.box-quote:focus-within, .box-quote.active-quote {
  border-color: var(--vivid-sky-blue);
}
.box-quote:focus-within .quote-icon, .box-quote.active-quote .quote-icon {
  color: var(--vivid-sky-blue);
}
.box-quote .box-quote-text {
  color: var(--color-primary-text);
  max-height: 10rem;
  overflow-y: scroll;
}
.box-quote .box-quote-textarea {
  background: transparent;
  color: var(--color-primary-text);
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2.5rem;
  resize: none;
  cursor: text;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
.box-quote .box-quote-textarea::placeholder {
  color: var(--color-secondary-text);
  font-size: 1.6rem;
}
.box-quote .quote-icon {
  position: absolute;
  top: -0.8rem;
  left: 1.2rem;
  color: var(--color-primary-text);
  height: 2rem;
  width: 2.2rem;
  border-radius: 0.4rem;
  font-size: 1.4rem;
}

.user-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 0.75rem;
  position: relative;
}
.user-card:last-child {
  margin-bottom: 0;
}
.user-card.selectable:hover {
  cursor: pointer;
  background-color: var(--color-secondary-container);
}
.user-card .user-img {
  height: 2.5rem;
  width: 2.5rem;
  min-width: 2.5rem;
  border-radius: 50%;
  margin: 0 1.25rem 0 1rem;
  overflow: hidden;
}
.user-card .user-img img, .user-card .user-img .default-user-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.user-card .user-img .default-user-img {
  font-size: 1rem;
}
.user-card .user-name {
  font-size: 1.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
  width: 100%;
  /*width: calc(100% - 4rem); */
}
.user-card .user-name .second-name {
  padding-left: 0.5rem;
  color: var(--color-secondary-text);
  font-size: 1.3rem;
}

::ng-deep #largeVideoContainer {
  background: var(--color-secondary-container) !important;
}

.group-items-left,
.group-items-center,
.group-items-right {
  display: flex;
  gap: 1.25rem;
}

.group-items-left {
  justify-content: flex-start;
}

.group-items-center {
  justify-content: center;
}

.group-items-right {
  justify-content: flex-end;
}

.group-items-space-between {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

[matripple] {
  touch-action: auto !important;
}

[mattooltip],
[mattooltipposition] {
  touch-action: auto !important;
}

.tooltip-default {
  color: var(--primary-white-text) !important;
  background: rgba(6, 15, 34, 0.87);
}
.tooltip-default:hover:not(:enabled) {
  display: none !important;
}

.tooltip-meeting-card,
.tooltip-meeting-card-canceled {
  margin-top: -1.7rem !important;
  margin-bottom: -1.7rem !important;
  font-weight: bold;
  font-size: 1rem;
}

.tooltip-meeting-card {
  background: rgba(6, 15, 34, 0.87);
  color: var(--caribbean-green) !important;
}

.tooltip-meeting-card-canceled {
  background: rgba(6, 15, 34, 0.87);
  color: var(--new-status-red) !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--caribbean-green) !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(18, 202, 150, 0.541) !important;
}

.clear-mat-form-field .mat-form-field-wrapper {
  padding-bottom: 0;
}
.clear-mat-form-field .mat-form-field-infix {
  padding: 0;
  border-top: none;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background: var(--caribbean-green);
}

.mat-radio-outer-circle {
  border-color: var(--color-secondary-text);
}

.mat-radio-label-content {
  font-weight: bold;
  font-size: 1.6rem;
  color: var(--color-primary-text);
}

.mat-radio-ripple {
  display: none;
}

.mat-calendar {
  background: var(--color-primary-container);
}

.mat-calendar-arrow {
  fill: var(--color-primary-text);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: var(--color-primary-text);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: var(--color-primary-text);
}

.mat-button-disabled {
  color: var(--grey-4) !important;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: var(--grey-4);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: hsla(var(--caribbean-green-h), var(--caribbean-green-s), var(--caribbean-green-l), 0.3);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: var(--color-secondary-text);
}

.mat-calendar-table-header-divider::after {
  background-color: hsla(var(--caribbean-green-h), var(--caribbean-green-s), var(--caribbean-green-l), 0.5);
}

.mat-calendar-body-selected {
  background-color: var(--caribbean-green);
  color: #fefefe;
}

.mat-option.hidden-value {
  display: none;
}

.mat-select-panel {
  background: var(--color-primary-container);
  scrollbar-color: rgba(6, 15, 34, 0) rgba(0, 0, 0, 0);
  scrollbar-width: thin;
}
.mat-select-panel::-webkit-scrollbar {
  width: 0.8rem;
}
.mat-select-panel::-webkit-scrollbar-track {
  border-radius: 0.4rem;
  background-color: rgba(0, 0, 0, 0);
  border: 0.1rem solid transparent;
}
.mat-select-panel::-webkit-scrollbar-thumb {
  border-radius: 0.4rem;
  background-color: rgba(6, 15, 34, 0);
  border: 0.1rem solid transparent;
  background-clip: padding-box;
}
.mat-select-panel:hover {
  scrollbar-color: rgba(6, 15, 34, 0.2) rgba(0, 0, 0, 0);
}
.mat-select-panel:hover::-webkit-scrollbar-track {
  border-radius: 0.4rem;
  background-color: rgba(0, 0, 0, 0);
  border: 0.1rem solid transparent;
}
.mat-select-panel:hover::-webkit-scrollbar-thumb {
  border-radius: 0.4rem;
  background-color: rgba(6, 15, 34, 0.2);
  border: 0.1rem solid transparent;
  background-clip: padding-box;
}
.mat-select-panel:active {
  scrollbar-color: rgba(6, 15, 34, 0.4) rgba(0, 0, 0, 0);
}
.mat-select-panel:active::-webkit-scrollbar-track {
  border-radius: 0.4rem;
  background-color: rgba(0, 0, 0, 0);
  border: 0.1rem solid transparent;
}
.mat-select-panel:active::-webkit-scrollbar-thumb {
  border-radius: 0.4rem;
  background-color: rgba(6, 15, 34, 0.2);
  border: 0.1rem solid transparent;
  background-clip: padding-box;
}
.mat-select-panel:active::-webkit-scrollbar-thumb:hover {
  background-color: rgba(6, 15, 34, 0.4);
}
.mat-select-panel:active::-webkit-scrollbar-thumb:active {
  background-color: #060f22;
}
.mat-select-panel.hidden {
  scrollbar-color: rgba(6, 15, 34, 0) rgba(0, 0, 0, 0) !important;
}
.mat-select-panel.hidden::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0) !important;
}
.mat-select-panel.hidden::-webkit-scrollbar-thumb {
  background-color: rgba(6, 15, 34, 0) !important;
}
.mat-select-panel .mat-option.mat-active {
  background: var(--color-dark-container);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: var(--color-dark-container);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: var(--color-dark-container);
}
.mat-select-panel .mat-option:hover:not(.mat-option-disabled), .mat-select-panel .mat-option:focus:not(.mat-option-disabled) {
  background: var(--color-secondary-container);
}

.mat-expansion-panel-header:hover:not([aria-disabled=true]) .mat-expansion-indicator::after {
  color: var(--caribbean-green) !important;
}

.mat-expansion-panel-header .mat-expansion-indicator::after {
  color: var(--color-secondary-text) !important;
}

.cdk-overlay-container {
  z-index: 8000;
}

.timepicker-backdrop-overlay,
.timepicker-overlay {
  z-index: 8500 !important;
}

.timepicker__header {
  background-color: var(--vivid-sky-blue) !important;
  border-radius: 0.6rem 0.6rem 0px 0px;
}

.timepicker__body {
  background-color: var(--color-primary-container) !important;
}

.timepicker__actions {
  background-color: var(--color-primary-container) !important;
  border-radius: 0px 0px 0.6rem 0.6rem;
}

.clock-face {
  background-color: var(--color-page-background) !important;
}

.clock-face__number--outer > span {
  color: var(--color-primary-text) !important;
}
.clock-face__number--outer > span.active {
  color: #fefefe !important;
}

.redirect-side-button {
  height: 3.5rem;
  width: 3rem;
  font-size: 2.4rem;
  text-decoration: none;
}
.redirect-side-button:hover i {
  color: var(--color-primary-text);
}
.redirect-side-button i {
  color: var(--color-secondary-text);
}

.message-icon {
  text-align: center;
  font-size: 2.5rem;
  color: #939498;
  position: relative;
}
.message-icon .badge-message {
  border-radius: 50%;
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  text-align: center;
  background: var(--caribbean-green);
  color: white;
  right: -0.7rem;
  top: -0.5rem;
  border: 0.15rem solid var(--color-secondary-container);
}
.message-icon .badge-message[data-messages]::after {
  font-size: 1rem;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: attr(data-messages);
}
.message-icon .badge-message[data-messages=""], .message-icon .badge-message[data-messages="0"] {
  display: none;
}
.message-icon .badge-message[data-messages=""]::after, .message-icon .badge-message[data-messages="0"]::after {
  display: none;
}
.message-icon .badge-message[data-messages="10+"] {
  width: 3.2rem;
  border-radius: 0.9rem;
}

.scrollbar-new {
  scrollbar-color: rgba(6, 15, 34, 0) rgba(0, 0, 0, 0);
  scrollbar-width: thin;
}
.scrollbar-new::-webkit-scrollbar {
  width: 0.8rem;
}
.scrollbar-new::-webkit-scrollbar-track {
  border-radius: 0.4rem;
  background-color: rgba(0, 0, 0, 0);
  border: 0.1rem solid transparent;
}
.scrollbar-new::-webkit-scrollbar-thumb {
  border-radius: 0.4rem;
  background-color: rgba(6, 15, 34, 0);
  border: 0.1rem solid transparent;
  background-clip: padding-box;
}
.scrollbar-new:hover {
  scrollbar-color: rgba(6, 15, 34, 0.2) rgba(0, 0, 0, 0);
}
.scrollbar-new:hover::-webkit-scrollbar-track {
  border-radius: 0.4rem;
  background-color: rgba(0, 0, 0, 0);
  border: 0.1rem solid transparent;
}
.scrollbar-new:hover::-webkit-scrollbar-thumb {
  border-radius: 0.4rem;
  background-color: rgba(6, 15, 34, 0.2);
  border: 0.1rem solid transparent;
  background-clip: padding-box;
}
.scrollbar-new:active {
  scrollbar-color: rgba(6, 15, 34, 0.4) rgba(0, 0, 0, 0);
}
.scrollbar-new:active::-webkit-scrollbar-track {
  border-radius: 0.4rem;
  background-color: rgba(0, 0, 0, 0);
  border: 0.1rem solid transparent;
}
.scrollbar-new:active::-webkit-scrollbar-thumb {
  border-radius: 0.4rem;
  background-color: rgba(6, 15, 34, 0.2);
  border: 0.1rem solid transparent;
  background-clip: padding-box;
}
.scrollbar-new:active::-webkit-scrollbar-thumb:hover {
  background-color: rgba(6, 15, 34, 0.4);
}
.scrollbar-new:active::-webkit-scrollbar-thumb:active {
  background-color: #060f22;
}
.scrollbar-new.hidden {
  scrollbar-color: rgba(6, 15, 34, 0) rgba(0, 0, 0, 0) !important;
}
.scrollbar-new.hidden::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0) !important;
}
.scrollbar-new.hidden::-webkit-scrollbar-thumb {
  background-color: rgba(6, 15, 34, 0) !important;
}

.chat-new-messages,
.chat-last-message {
  width: 100%;
  font-size: 1.4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 0.25rem;
}

.chat-new-messages {
  color: var(--color-sky-blue-text);
}

.chat-last-message {
  color: var(--color-secondary-text);
}

.pipe-highlight {
  color: white;
  background: rgba(254, 254, 254, 0.2);
}

.form-style-3 label {
  display: block;
  padding: 1rem;
}
.form-style-3 label p {
  margin: 0 0.5rem;
}
.form-style-3 label .third {
  width: calc(33% - 0.5rem);
  margin: 0 0.25rem;
}
.form-style-3 label .half {
  width: calc(50% - 1rem);
  margin: 0 0.5rem;
}
.form-style-3 label .full {
  width: calc(100% - 1rem);
  margin: 0 0.5rem;
}
.form-style-3 label input,
.form-style-3 label select,
.form-style-3 label textarea {
  height: 3rem;
}

.form-style-4 .form-inputs {
  padding: 0rem 1rem 1rem 1rem;
  overflow: auto;
}
.form-style-4 .form-inputs label {
  width: 100%;
  padding: 1rem;
  display: block;
}
.form-style-4 .form-inputs label.optional p::after {
  content: " optional";
  font-style: italic;
  font-size: 1.4rem;
}
.form-style-4 .form-inputs label.search input {
  width: calc(100% - 3rem);
  background: none;
}
.form-style-4 .form-inputs label.search button {
  border: none;
  width: 3rem;
  background: none;
}
.form-style-4 .form-inputs label.search button:hover {
  background: none;
}
.form-style-4 .form-inputs label p {
  font-size: 1.7rem;
  margin-bottom: 1rem;
  display: block;
}
.form-style-4 .form-inputs label input {
  padding: 1rem;
  border: rgba(0, 0, 0, 0.4) solid 0.1rem;
  pointer-events: initial;
  height: 4rem;
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 10rem);
}
.form-style-4 .form-inputs label button {
  height: 4rem;
  width: 10rem;
  font-size: 1.7rem;
  display: inline-block;
  vertical-align: top;
  border-radius: 0;
  border: rgba(0, 0, 0, 0.4) solid 0.1rem;
  border-left: none;
}
.form-style-4 .form-inputs label button.not-valid {
  color: grey;
}
.form-style-4 .form-inputs label button.not-valid:hover {
  cursor: default;
}
.form-style-4 .form-inputs label button:hover {
  background: rgba(0, 0, 0, 0.15);
}

.form-no-labels {
  margin: auto;
  width: 100%;
}
.form-no-labels .form-inputs input {
  display: block;
  height: 4rem;
  width: 100%;
  line-height: 4rem;
  margin: 1rem 0.5rem;
  padding: 1rem;
  border: rgba(0, 0, 0, 0.4) solid 1px;
  border-radius: 2px;
}
.form-no-labels .form-inputs .radiobox,
.form-no-labels .form-inputs .checkbox {
  display: block;
  width: calc(100% - 1rem);
  line-height: 4rem;
  margin: 1rem 0.5rem;
  padding: 1rem;
  font-size: 1.6rem;
}
.form-no-labels .form-inputs .radiobox p,
.form-no-labels .form-inputs .checkbox p {
  margin-left: 1rem;
  font-size: 1.6rem;
  display: inline-block;
  vertical-align: middle;
}
.form-no-labels .form-inputs .radiobox input[type=radio],
.form-no-labels .form-inputs .radiobox input[type=checkbox],
.form-no-labels .form-inputs .checkbox input[type=radio],
.form-no-labels .form-inputs .checkbox input[type=checkbox] {
  width: initial;
  display: inline-block;
  vertical-align: middle;
}
.form-no-labels .form-inputs .select {
  display: block;
  width: 100%;
  margin: 1rem 0.5rem;
}
.form-no-labels .form-inputs .select select {
  display: block;
  height: 4rem;
  width: 100%;
  padding: 1rem;
  line-height: 4rem;
  border: rgba(0, 0, 0, 0.4) solid 1px;
  border-radius: 2px;
}
.form-no-labels .form-inputs .select select option {
  color: black;
}
.form-no-labels .form-inputs .with-btn {
  display: block;
  height: 4rem;
  width: 100%;
  margin: 1rem 0.5rem;
  border: rgba(0, 0, 0, 0.4) solid 1px;
  border-radius: 2px;
}
.form-no-labels .form-inputs .with-btn input {
  height: 100%;
  padding: 1rem;
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: calc(100% - 4rem);
  border: none;
}
.form-no-labels .form-inputs .with-btn button {
  height: 100%;
  width: 4rem;
  display: inline-block;
  vertical-align: middle;
}
.form-no-labels .form-inputs .full {
  display: block;
  width: calc(100% - 1rem);
}
.form-no-labels .form-inputs .half {
  display: inline-block;
  vertical-align: top;
  width: calc(50% - 1rem);
}
.form-no-labels .form-inputs .quarter {
  display: inline-block;
  vertical-align: top;
  width: calc(25% - 1rem);
}
.form-no-labels .form-inputs .third {
  display: inline-block;
  vertical-align: top;
  width: calc(33.333% - 1rem);
}
.form-no-labels .form-inputs .two-third {
  display: inline-block;
  vertical-align: top;
  width: calc(66.666% - 1rem);
}
.form-no-labels .form-inputs .one-eight {
  display: inline-block;
  vertical-align: top;
  width: calc(12.5% - 1rem);
}
.form-no-labels .form-inputs .validation-errors {
  display: block;
  width: 100%;
  height: 1.36rem;
  margin-bottom: 1rem;
}
.form-no-labels .form-inputs .validation-errors p {
  padding: 0 1rem;
  margin: 0;
  font-size: 1.2rem;
}
.form-no-labels .form-button button {
  width: 100%;
  height: 5rem;
  background: var(--color-secondary-container);
}
.form-no-labels .form-button button.not-valid {
  color: grey;
}
.form-no-labels .form-button button.not-valid:hover {
  background: var(--color-secondary-container);
  cursor: default;
}
.form-no-labels .form-button button:hover {
  background: rgba(0, 0, 0, 0.15);
}

.form-no-borders .form-inputs input,
.form-no-borders .form-inputs input[type=text] {
  border-radius: 0;
  border: none;
  padding-left: 0;
  border-bottom: var(--color-secondary-text) solid 0.1rem;
  background: rgba(0, 0, 0, 0);
  color: var(--color-primary-text);
  font-size: 1.6rem;
}
.form-no-borders .form-inputs input::placeholder,
.form-no-borders .form-inputs input[type=text]::placeholder {
  color: var(--color-secondary-text);
  font-size: 1.6rem;
}
.form-no-borders .form-inputs input:-webkit-autofill, .form-no-borders .form-inputs input:-webkit-autofill:hover, .form-no-borders .form-inputs input:-webkit-autofill:focus,
.form-no-borders .form-inputs input[type=text]:-webkit-autofill,
.form-no-borders .form-inputs input[type=text]:-webkit-autofill:hover,
.form-no-borders .form-inputs input[type=text]:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--color-primary-text);
  caret-color: var(--color-primary-text);
}
.form-no-borders .form-inputs input.required::placeholder,
.form-no-borders .form-inputs input[type=text].required::placeholder {
  color: var(--red);
}
.form-no-borders .form-inputs .select select {
  border-radius: 0;
  border: none;
  padding-left: 0;
  border-bottom: var(--color-secondary-text) solid 0.1rem;
  background: rgba(0, 0, 0, 0);
  color: var(--color-primary-text);
  font-size: 1.6rem;
}
.form-no-borders .form-inputs .radiobox,
.form-no-borders .form-inputs .checkbox {
  height: 2.5rem;
  line-height: 2.5rem;
  padding-left: 0;
  margin-top: 1rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.form-no-borders .form-inputs .radiobox:hover input ~ .checkmark,
.form-no-borders .form-inputs .checkbox:hover input ~ .checkmark {
  background-color: rgba(0, 0, 0, 0.15);
}
.form-no-borders .form-inputs .radiobox:hover input ~ .radioselect,
.form-no-borders .form-inputs .checkbox:hover input ~ .radioselect {
  background-color: rgba(0, 0, 0, 0.15);
}
.form-no-borders .form-inputs .radiobox input[type=radio],
.form-no-borders .form-inputs .radiobox input[type=checkbox],
.form-no-borders .form-inputs .checkbox input[type=radio],
.form-no-borders .form-inputs .checkbox input[type=checkbox] {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;
  display: inline-block;
  vertical-align: middle;
  display: none;
}
.form-no-borders .form-inputs .radiobox input[type=radio]:checked ~ .checkmark,
.form-no-borders .form-inputs .radiobox input[type=checkbox]:checked ~ .checkmark,
.form-no-borders .form-inputs .checkbox input[type=radio]:checked ~ .checkmark,
.form-no-borders .form-inputs .checkbox input[type=checkbox]:checked ~ .checkmark {
  background: rgba(0, 0, 0, 0.1);
}
.form-no-borders .form-inputs .radiobox input[type=radio]:checked ~ .radioselect,
.form-no-borders .form-inputs .radiobox input[type=checkbox]:checked ~ .radioselect,
.form-no-borders .form-inputs .checkbox input[type=radio]:checked ~ .radioselect,
.form-no-borders .form-inputs .checkbox input[type=checkbox]:checked ~ .radioselect {
  background: rgba(0, 0, 0, 0.1);
}
.form-no-borders .form-inputs .radiobox .checkmark,
.form-no-borders .form-inputs .checkbox .checkmark {
  height: 1.5rem;
  width: 1.5rem;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  border: #fefefe solid 0.1rem;
  background: #fefefe;
}
.form-no-borders .form-inputs .radiobox .radioselect,
.form-no-borders .form-inputs .checkbox .radioselect {
  height: 1.6rem;
  width: 1.6rem;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  border: #fefefe solid 0.1rem;
  background: #fefefe;
}
.form-no-borders .form-inputs .radiobox p,
.form-no-borders .form-inputs .checkbox p {
  padding: 0rem;
  margin: 0 2rem;
  display: inline-block;
  vertical-align: middle;
  color: var(--color-primary-text);
  font-size: 1.6rem;
}
.form-no-borders .form-inputs .with-btn {
  border-radius: 0;
  border: none;
  border-bottom: var(--color-secondary-text) solid 0.1rem;
  background: rgba(0, 0, 0, 0);
}
.form-no-borders .form-inputs .with-btn input {
  padding-left: 0;
}

.content-module,
.content-module-alt,
.content-module-full {
  background: none;
  padding: 0;
  border-radius: 2px;
}
.content-module .small-header,
.content-module-alt .small-header,
.content-module-full .small-header {
  font-size: 1.5rem;
  margin: 0.5rem 1.5rem 1rem 0;
  text-align: right;
}

.content-module-full {
  height: calc(100% - 1.5rem);
  padding: 0.5rem;
}

.chat .intake-chat-messaging {
  display: grid;
  grid-gap: 1rem;
  max-width: 110rem;
  height: 100%;
  margin: auto;
  padding: 1rem;
  background-color: var(--color-primary-icon);
  grid-template-rows: 5rem 50rem;
  grid-template-columns: 29rem minmax(0, 1fr);
  grid-template-areas: "users info" "users box";
}
.chat .intake-chat-messaging .user-info-bar {
  grid-area: info;
  height: 5rem;
  width: 100%;
  background-color: var(--color-secondary-container);
  display: flex;
  flex-flow: row nowrap;
}
.chat .intake-chat-messaging .user-info-bar.offline, .chat .intake-chat-messaging .user-info-bar.none, .chat .intake-chat-messaging .user-info-bar.hidden {
  --statc: var(--status-grey2);
  --statt: " offline";
}
.chat .intake-chat-messaging .user-info-bar.away {
  --statc: var(--status-grey2);
  --statt: " away";
}
.chat .intake-chat-messaging .user-info-bar.active {
  --statc: var(--caribbean-green);
  --statt: " active";
}
.chat .intake-chat-messaging .user-info-bar.dnd {
  --statc: var(--new-status-orange);
  --statt: " busy";
}
.chat .intake-chat-messaging .user-info-bar.brb {
  --statc: var(--new-status-yellow2);
  --statt: " be right back";
}
.chat .intake-chat-messaging .user-info-bar .user-img {
  display: inline-block;
  vertical-align: top;
  height: 5rem;
  width: 5rem;
  padding: 1rem;
  flex-grow: 0;
  overflow: hidden;
  border-radius: 50%;
}
.chat .intake-chat-messaging .user-info-bar .user-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.chat .intake-chat-messaging .user-info-bar .chat-name {
  display: inline-block;
  vertical-align: top;
  flex-grow: 0;
  color: var(--color-primary-text);
  padding: 0.5rem 0;
  height: 5rem;
}
.chat .intake-chat-messaging .user-info-bar .chat-name span {
  line-height: 2rem;
  height: 2rem;
  display: block;
  margin: 0;
  padding: 0;
}
.chat .intake-chat-messaging .user-info-bar .chat-name .top-name {
  color: var(--color-primary-text);
}
.chat .intake-chat-messaging .user-info-bar .chat-name .bottom-name {
  font-size: 1.4rem;
  color: var(--color-secondary-text);
}
.chat .intake-chat-messaging .user-info-bar .channel-name {
  display: inline-block;
  vertical-align: top;
  line-height: 3rem;
  flex-grow: 0;
  color: #fefefe;
  padding: 1rem 1.5rem;
}
.chat .intake-chat-messaging .user-info-bar .status-text {
  display: inline-block;
  line-height: 5rem;
  margin-left: 1.5rem;
  vertical-align: top;
  color: var(--statc);
  flex-grow: 0;
}
.chat .intake-chat-messaging .user-info-bar .status-text::after {
  content: var(--statt);
}
.chat .intake-chat-messaging .user-info-bar .status-text .status-circle-sm {
  width: 0.75rem;
  height: 0.75rem;
  background: var(--statc);
  border-radius: 50%;
  display: inline-block;
}
.chat .intake-chat-messaging .user-info-bar .channel-bar-buttons {
  display: inline-block;
  vertical-align: top;
  text-align: right;
  flex-grow: 1;
  margin: 0rem 1rem;
}
.chat .intake-chat-messaging .user-info-bar .channel-bar-buttons button {
  height: 5rem;
  padding: 0rem 0.75rem;
  background: none;
  display: inline-block;
  vertical-align: middle;
}
.chat .intake-chat-messaging .user-info-bar .channel-bar-buttons button i {
  color: #fefefe;
  font-size: 2.5rem;
}
.chat .intake-chat-messaging app-chat {
  grid-area: box;
}
.chat .intake-chat-messaging app-chat .unauthorized {
  width: 100%;
  height: 100%;
  background: var(--color-secondary-container);
  position: relative;
}
.chat .intake-chat-messaging app-chat .unauthorized h3 {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.chat .intake-chat-messaging .chat-users {
  background: var(--color-secondary-container);
  grid-area: users;
  overflow: auto;
}
.chat .intake-chat-messaging .chat-users .chat-sort {
  margin: 1rem 1rem 0.25rem 1rem;
}
.chat .intake-chat-messaging .chat-users .chat-sort p {
  text-align: right;
  font-size: 1.4rem;
  color: var(--color-secondary-text);
}
.chat .intake-chat-messaging .chat-users .chat-user {
  margin: 0.5rem 1rem 0.5rem 1rem;
  padding: 0.25rem 0rem;
  height: 4rem;
}
.chat .intake-chat-messaging .chat-users .chat-user.offline, .chat .intake-chat-messaging .chat-users .chat-user.none, .chat .intake-chat-messaging .chat-users .chat-user.hidden {
  --statc: var(--status-grey2);
}
.chat .intake-chat-messaging .chat-users .chat-user.away {
  --statc: var(--status-grey2);
}
.chat .intake-chat-messaging .chat-users .chat-user.active {
  --statc: var(--caribbean-green);
}
.chat .intake-chat-messaging .chat-users .chat-user.dnd {
  --statc: var(--new-status-orange);
}
.chat .intake-chat-messaging .chat-users .chat-user.brb {
  --statc: var(--new-status-yellow2);
}
.chat .intake-chat-messaging .chat-users .chat-user:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.1);
}
.chat .intake-chat-messaging .chat-users .chat-user .status-bar {
  height: 3rem;
  width: calc(3rem / 5);
  margin: 0.25rem 0rem 0.25rem 0.75rem;
  background: var(--statc);
  display: inline-block;
  vertical-align: top;
}
.chat .intake-chat-messaging .chat-users .chat-user .user-img {
  height: 3.5rem;
  width: 3.5rem;
  display: inline-block;
  vertical-align: top;
  padding: 0.25rem 0.25rem 0.25rem 0rem;
  overflow: hidden;
  border-radius: 50%;
}
.chat .intake-chat-messaging .chat-users .chat-user .user-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.chat .intake-chat-messaging .chat-users .chat-user .chat-name {
  display: inline-block;
  vertical-align: top;
  line-height: 3.5rem;
  padding: 0rem 1rem;
}
.chat .intake-chat-messaging .users-in-channel {
  grid-area: attendance;
  display: block;
  padding: 1rem;
  background-color: var(--color-primary-container);
  overflow: auto;
}
.chat .intake-chat-messaging .users-in-channel .box-message {
  text-align: center;
  display: block;
  margin: 0.5rem 0rem 1.5rem 0rem;
  font-size: 1.4rem;
}
.chat .intake-chat-messaging .users-in-channel .user-card {
  min-height: 4.5rem;
  background-color: var(--color-secondary-container);
  margin-bottom: 0.25rem;
  overflow: hidden;
  transition: all 0.4s ease-in-out, background-color 0s;
}
.chat .intake-chat-messaging .users-in-channel .user-card.offline, .chat .intake-chat-messaging .users-in-channel .user-card.hidden {
  --statc: var(--status-grey2);
  --statt: " offline";
}
.chat .intake-chat-messaging .users-in-channel .user-card.away {
  --statc: var(--status-grey2);
  --statt: " away";
}
.chat .intake-chat-messaging .users-in-channel .user-card.active {
  --statc: var(--caribbean-green);
  --statt: " active";
}
.chat .intake-chat-messaging .users-in-channel .user-card.dnd {
  --statc: var(--new-status-orange);
  --statt: " busy";
}
.chat .intake-chat-messaging .users-in-channel .user-card.brb {
  --statc: var(--new-status-yellow2);
  --statt: " be right back";
}
.chat .intake-chat-messaging .users-in-channel .user-card .user-img-fix {
  margin: 0 0.5rem 0rem 1rem;
}
.chat .intake-chat-messaging .users-in-channel .user-card .user-img-fix img {
  height: 2.5rem;
  width: 2.5rem;
  object-fit: cover;
}
.chat .intake-chat-messaging .users-in-channel .user-card .user-img-fix .status-bar {
  display: flex;
  background: var(--statc);
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  box-shadow: inset 0 0 0.15rem 0.015rem rgba(0, 0, 0, 0.4), 0 0 0 0.25rem var(--color-secondary-container);
  min-width: 1rem;
  margin: 1.5rem 0rem 0rem -0.5rem;
}
.chat .intake-chat-messaging .users-in-channel .user-card .chat-name {
  color: var(--color-primary-text);
  font-weight: bold;
}
.chat .intake-chat-messaging .tempFileBtn {
  grid-area: files;
}
.chat .intake-chat-messaging .chat-users-ao,
.chat .intake-chat-messaging .chat-users-io {
  display: none;
}
.chat .intake-chat-messaging .officer-content {
  display: none;
}

.video-stream {
  margin-bottom: 1rem;
}
.video-stream[stream-type=a-officer] {
  margin-bottom: 0rem;
}
.video-stream[stream-type=a-officer] .stream-player {
  min-height: 0;
  transition: min-height 0.4s ease-in-out;
}
.video-stream[stream-type=a-officer] .stream-player .stream-bar-overlay .video-screen-share {
  display: none;
}
.video-stream[stream-type=a-officer] .video-request-btn {
  display: initial;
}
.video-stream[stream-type=direct-message], .video-stream[stream-type=quick-meeting] {
  min-height: 0;
  margin-bottom: 0;
}
.video-stream .request-overlay {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
}
.video-stream .request-overlay .request-box {
  min-height: 5rem;
  width: 30rem;
  background: var(--color-dark-container);
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 1.25rem 0 0 0;
  transform: translate(-50%, -50%);
}
.video-stream .request-overlay .request-box .request-message {
  width: 100%;
  display: flex;
  height: calc(100% - 5rem);
  text-align: center;
  align-items: center;
  margin: 2.5rem 0 0 0;
}
.video-stream .request-overlay .request-box .request-message h4 {
  margin: 0 3rem;
}
.video-stream .request-overlay .request-box .request-message .error {
  margin: 0 6rem;
  text-align: center;
  color: red;
  font-size: 1.3;
}
.video-stream .request-overlay .request-box .request-buttons {
  width: 100%;
  margin: 2.5rem 0 1.25rem 0;
}
.video-stream .request-overlay .request-box .request-buttons button {
  background: var(--vivid-sky-blue);
  display: block;
  border-radius: 0;
  width: 75%;
  height: 5rem;
  margin: 0.5rem auto;
}
.video-stream .request-overlay .request-box .request-stream-settings {
  width: 100%;
  height: 4rem;
  display: block;
  overflow: hidden;
  transition: height 0.4s ease-out;
}
.video-stream .request-overlay .request-box .request-stream-settings.open {
  height: 20rem;
}
.video-stream .request-overlay .request-box .request-stream-settings .request-settings-title {
  display: flex;
  height: 4rem;
  justify-content: space-between;
  padding: 1rem 2rem;
}
.video-stream .request-overlay .request-box .request-stream-settings .request-settings-title p,
.video-stream .request-overlay .request-box .request-stream-settings .request-settings-title span {
  line-height: 2rem;
  display: inline-block;
  vertical-align: top;
}
.video-stream .request-overlay .request-box .request-stream-settings .request-settings-title span {
  font-size: 2rem;
  cursor: pointer;
}
.video-stream .request-overlay .request-box .request-stream-settings .request-settings-title span i:hover {
  color: var(--color-primary-icon);
}
.video-stream .request-overlay .request-box .request-stream-settings .request-settings-list {
  height: 16rem;
  padding: 1rem 2rem;
  overflow: auto;
}
.video-stream .request-overlay .request-box .request-stream-settings .request-settings-list .select {
  margin-bottom: 2rem;
}
.video-stream .request-overlay .request-box .request-stream-settings .request-settings-list .select label {
  font-size: 1.4rem;
}
.video-stream .request-overlay .request-box .request-stream-settings .request-settings-list .select select {
  margin: 1rem auto;
  display: block;
  width: 90%;
  height: 2rem;
  color: black;
}
.video-stream .request-overlay .request-box .request-stream-settings .request-settings-list .select select option {
  color: black;
}
.video-stream .request-overlay .request-box .request-stream-settings .request-settings-list .select p {
  margin-top: 1rem;
  font-size: 1.3rem;
  text-align: center;
}
.video-stream .request-overlay .request-box .request-stream-settings .request-settings-list .select p a {
  text-decoration: underline;
  font-size: 1.3rem;
}
.video-stream .request-overlay .request-box .request-stream-settings .request-settings-list .select p a:hover {
  color: var(--vivid-sky-blue);
}
.video-stream .request-overlay .request-box .request-stream-settings .request-settings-list .checkbox {
  margin-top: 2rem;
  padding: 0 2rem;
  height: 2rem;
}
.video-stream .request-overlay .request-box .request-stream-settings .request-settings-list .checkbox label,
.video-stream .request-overlay .request-box .request-stream-settings .request-settings-list .checkbox input {
  display: inline-block;
  vertical-align: middle;
  line-height: 2rem;
}
.video-stream .request-overlay .request-box .request-stream-settings .request-settings-list .checkbox label:hover,
.video-stream .request-overlay .request-box .request-stream-settings .request-settings-list .checkbox input:hover {
  cursor: pointer;
}
.video-stream .request-overlay .request-box .request-stream-settings .request-settings-list .checkbox label {
  text-align: left;
  width: 90%;
}
.video-stream .request-overlay .request-box .request-stream-settings .request-settings-list .checkbox input {
  width: 10%;
  position: relative;
  padding: auto;
}
.video-stream .request-overlay .request-box .request-stream-settings .request-settings-list .device-check {
  display: block;
  margin: auto;
  text-align: center;
  text-decoration: underline;
  font-size: 1.35rem;
}
.video-stream .request-overlay .request-box .request-stream-settings .request-settings-list .device-check:hover {
  color: var(--vivid-sky-blue);
}

.emoji-picker__wrapper {
  top: auto !important;
  bottom: 12rem !important;
  z-index: 8500 !important;
}
@media only screen and (min-width: 560px) {
  .emoji-picker__wrapper {
    top: initial !important;
    bottom: 0.2rem !important;
  }
}

.emoji-picker.dark {
  background: hsla(var(--color-primary-container-h), var(--color-primary-container-s), var(--color-primary-container-l), 0.9) !important;
  border: none !important;
  border-radius: 1rem !important;
}

.emoji-picker.dark .emoji-picker__search {
  color: var(--color-primary-text) !important;
  background: var(--color-input-background) !important;
  border: none !important;
  border-radius: 0.5rem !important;
}

.emoji-picker__search-icon {
  top: calc(50% - 0.6em) !important;
}

button.emoji-picker__category-button.active {
  border-bottom: 1.5px solid var(--color-primary-text) !important;
  border-radius: 0 !important;
}

.emoji-picker__container {
  margin-top: -0.4rem !important;
}

.emoji-picker__emojis {
  scrollbar-color: rgba(6, 15, 34, 0) rgba(0, 0, 0, 0);
  scrollbar-width: thin;
}
.emoji-picker__emojis::-webkit-scrollbar {
  width: 0.8rem;
}
.emoji-picker__emojis::-webkit-scrollbar-track {
  border-radius: 0.4rem;
  background-color: rgba(0, 0, 0, 0);
  border: 0.1rem solid transparent;
}
.emoji-picker__emojis::-webkit-scrollbar-thumb {
  border-radius: 0.4rem;
  background-color: rgba(6, 15, 34, 0);
  border: 0.1rem solid transparent;
  background-clip: padding-box;
}
.emoji-picker__emojis:hover {
  scrollbar-color: rgba(6, 15, 34, 0.2) rgba(0, 0, 0, 0);
}
.emoji-picker__emojis:hover::-webkit-scrollbar-track {
  border-radius: 0.4rem;
  background-color: rgba(0, 0, 0, 0);
  border: 0.1rem solid transparent;
}
.emoji-picker__emojis:hover::-webkit-scrollbar-thumb {
  border-radius: 0.4rem;
  background-color: rgba(6, 15, 34, 0.2);
  border: 0.1rem solid transparent;
  background-clip: padding-box;
}
.emoji-picker__emojis:active {
  scrollbar-color: rgba(6, 15, 34, 0.4) rgba(0, 0, 0, 0);
}
.emoji-picker__emojis:active::-webkit-scrollbar-track {
  border-radius: 0.4rem;
  background-color: rgba(0, 0, 0, 0);
  border: 0.1rem solid transparent;
}
.emoji-picker__emojis:active::-webkit-scrollbar-thumb {
  border-radius: 0.4rem;
  background-color: rgba(6, 15, 34, 0.2);
  border: 0.1rem solid transparent;
  background-clip: padding-box;
}
.emoji-picker__emojis:active::-webkit-scrollbar-thumb:hover {
  background-color: rgba(6, 15, 34, 0.4);
}
.emoji-picker__emojis:active::-webkit-scrollbar-thumb:active {
  background-color: #060f22;
}
.emoji-picker__emojis.hidden {
  scrollbar-color: rgba(6, 15, 34, 0) rgba(0, 0, 0, 0) !important;
}
.emoji-picker__emojis.hidden::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0) !important;
}
.emoji-picker__emojis.hidden::-webkit-scrollbar-thumb {
  background-color: rgba(6, 15, 34, 0) !important;
}

.pointer {
  cursor: pointer;
}

.flex-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-row-nowrap {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.flex-row-wrap-center-all {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.flex-row-nowrap-center-all {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.flex {
  display: flex;
}

.flex-center-all {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-col-center-all {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

/*
    spacing key 

    🔑 : {
        0 = 0rem,
        1 = 0.5rem,
        2 = 1rem,
        3 = 1.5rem,
        4 = 3rem,
    }
*/
.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-auto {
  margin-top: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.ms-auto {
  margin-left: auto;
}

.me-auto {
  margin-right: auto;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 0.5rem;
}

.m-2 {
  margin: 1rem;
}

.m-3 {
  margin: 1.5rem;
}

.m-4 {
  margin: 3rem;
}

.ms-0 {
  margin-left: 0;
}

.ms-1 {
  margin-left: 0.5rem;
}

.ms-2 {
  margin-left: 1rem;
}

.ms-3 {
  margin-left: 1.5rem;
}

.ms-4 {
  margin-left: 3rem;
}

.me-0 {
  margin-right: 0;
}

.me-1 {
  margin-right: 0.5rem;
}

.me-2 {
  margin-right: 1rem;
}

.me-3 {
  margin-right: 1.5rem;
}

.me-4 {
  margin-right: 3rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mt-4 {
  margin-top: 3rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.mb-4 {
  margin-bottom: 3rem;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-1 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mx-2 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-3 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-4 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-3 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-4 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 0.5rem;
}

.p-2 {
  padding: 1rem;
}

.p-3 {
  padding: 1.5rem;
}

.p-4 {
  padding: 3rem;
}

.ps-0 {
  padding-left: 0;
}

.ps-1 {
  padding-left: 0.5rem;
}

.ps-2 {
  padding-left: 1rem;
}

.ps-3 {
  padding-left: 1.5rem;
}

.ps-4 {
  padding-left: 3rem;
}

.pe-0 {
  padding-right: 0;
}

.pe-1 {
  padding-right: 0.5rem;
}

.pe-2 {
  padding-right: 1rem;
}

.pe-3 {
  padding-right: 1.5rem;
}

.pe-4 {
  padding-right: 3rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 0.5rem;
}

.pt-2 {
  padding-top: 1rem;
}

.pt-3 {
  padding-top: 1.5rem;
}

.pt-4 {
  padding-top: 3rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 0.5rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.pb-3 {
  padding-bottom: 1.5rem;
}

.pb-4 {
  padding-bottom: 3rem;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-1 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.px-2 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-3 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-4 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-3 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-4 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.h-100 {
  height: 100vh;
}