$breakpoints: (
    "phone": 400px,
    "phone-wide": 480px,
    "phablet": 560px,
    "tablet-small": 640px,
    "tablet": 768px,
    "tablet-wide": 1024px,
    "desktop-small": 1248px,
    "temp-for-home": 1350px,
    // temp mobile fix
    "desktop": 1440px,
    "desktop-wide": 1650px,
    // max doc page width is set to 1600px in styles.scss 151 (max-width:160rem)
    "desktop-ultra": 1921px // ,,
);

// use a breakpoint key or type in a custom size
// ex1 @include mq('tablet-wide') {}
// ex2 @include mq('1024px') {}

@mixin mq($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    } @else {
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}

// @mixin alpha($property, $color-variable, $opacity) {
//   $color-variable-h: var(#{$color-variable+'-h'});
//   $color-variable-s: var(#{$color-variable+'-s'});
//   $color-variable-l: var(#{$color-variable+'-l'});
//   #{$property}: hsla($color-variable-h, $color-variable-s, $color-variable-l, $opacity);
// }



// 💡 flex mixins for use with Media Queries :
/*
    .example-el {
        padding: 1rem 3rem;
        margin-bottom: 1rem;
        
        @include flex-col-center-all(); //  < 560px is flex column 
        
        @include mq('phablet') {
            
            @include flex-row-nowrap-center-all(); //  > 560px is flex row 
        }
*/

// rows
@mixin flex-row {
    display: flex;
    flex-direction: row;
}
@mixin flex-row-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
@mixin flex-row-nowrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}
@mixin flex-row-wrap-center-all {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
@mixin flex-row-nowrap-center-all {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

// cols
@mixin flex-col {
    display: flex;
    flex-direction: column;
}
@mixin flex-col-center-all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


////Form Inputs
@mixin borderless-input {
    display: block;
    width: 100%;
    border: none;
    font-weight: bold;
    padding-left: 0.4rem; //To match padding on select
    margin-bottom: 2rem;
    line-height: 2.5rem;
    background: rgba(0, 0, 0, 0);
    font-size: 1.6rem;
    color: $color-primary-text;
    border-bottom: $color-secondary-text solid 0.1rem;

    &::placeholder {
        color: $color-secondary-text;
        font-size: 1.6rem;
    }

    &:disabled {
        opacity: 0.6;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        -webkit-text-fill-color: $color-primary-text;
        caret-color: $color-primary-text;
    }

    // &.required {
    //     &::placeholder {
    //         // To do
    //     }
    // }

    &.half {
        display: inline-block;
        width: calc(50% - 1rem);
    }
}

@mixin round-input {
    // color: $color-primary-text;
    font-size: 1.5rem;
    font-weight: bold;
    border-radius: 2.5rem;
    padding: 1rem 3rem;
    height: 4.2rem;
    white-space: nowrap; // cote added
    // cursor: pointer; // cote added
}

@mixin select-box {
    transform-origin: 100% 0%; // cote added see line 562 - @mixin select-box-animation
    // border-radius: 0.5rem;
    // border-radius: 0.5rem 0 0.5rem 0.5rem; // cote testing ...
    border-radius: 1rem 0 1rem 1rem; // cote testing ...
    background-color: $color-primary-container;
    border: 0.1rem solid $color-secondary-container; // $grey-4
    z-index: $z-modal;
    box-shadow: -0.5rem 0.5rem 1rem 0rem rgba(0, 0, 0, 0.32);

    a {
        text-decoration: none;
    }

    .empty-list,
    .list-item-initial,
    .list-item {
        padding: 0.5rem 1rem;
        width: 100%;
        border-radius: 0;
        color: $color-secondary-text;
    }

    .empty-list {
        font-size: 1.4rem;
    }

    .list-item-initial,
    .list-item {
        cursor: pointer;

        &:hover {
            color: $color-primary-text;
            background-color: $color-secondary-container; // $grey-4
            i {
                color: $color-primary-text;
            }
        }

        i {
            text-align: center; // cote edit for icon alignment: width is so they are all the same width & centered
            margin-right: 0.5rem;
            color: $color-secondary-text;
            font-size: 1.2rem;
            width: 2rem;
        }
        // specific icons //
        .calendar-icon {
            // calander icons are slightly smaller & need adjust ...
            font-size: 1.4rem;
        }
        .trash-icon {
            // w/ <i class="far fa-trash-alt"></i>
            font-size: 1.4rem;
        }
        .edit-icon {
            // w/ <i class="far fa-edit"></i>
            text-align: right;
            padding-right: 0.2rem;
            font-size: 1.4rem;
        }
        // custom icon used with <i class="fas fa-info"></i> ...
        .info-icon {
            font-size: 1.4rem;
        }
        // .fa-spinner {
        // }
    }

    .list-item {
        display: flex;
        align-items: center;
    }

    .list-item-initial {
        display: initial;
    }

    .user-card {
        margin-bottom: 0;
    }
}

@mixin select-box-animation {
    animation: dropdown-box 272ms ease-out;
    @keyframes dropdown-box {
        from {
            opacity: 0;
            transform: scaleY(0.4) translateY(-3rem);
        }
        27% {
            opacity: 0;
            transform: scaleY(0.4) translateY(-3rem);
        }
        to {
            opacity: 1;
            transform: scaleY(1) translateY(0rem);
        }
    }
}

@mixin default-scroll {
    scrollbar-color: rgba(6, 15, 34, 0) rgba(0, 0, 0, 0); //firefox
    scrollbar-width: thin; // 💡 firefox thin is 0.8rem | 8px ... 16px is normal width ...
    &::-webkit-scrollbar {
        width: 0.8rem; // same as firefox thin ...
    }
    &::-webkit-scrollbar-track {
        border-radius: 0.4rem;
        background-color: rgba(0, 0, 0, 0);
        border: 0.1rem solid transparent;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 0.4rem;
        background-color: rgba(6, 15, 34, 0);
        border: 0.1rem solid transparent;
        background-clip: padding-box;
    }
    // show scroll thumb on hover ...
    &:hover {
        scrollbar-color: rgba(6, 15, 34, 0.2) rgba(0, 0, 0, 0); //firefox
        // chrome
        &::-webkit-scrollbar-track {
            border-radius: 0.4rem;
            background-color: rgba(0, 0, 0, 0);
            border: 0.1rem solid transparent;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 0.4rem;
            background-color: rgba(6, 15, 34, 0.2);
            border: 0.1rem solid transparent;
            background-clip: padding-box;
        }
    }
    // highlight scroll thumb when interacting ...
    &:active {
        scrollbar-color: rgba(6, 15, 34, 0.4) rgba(0, 0, 0, 0); //firefox
        // chrome
        &::-webkit-scrollbar-track {
            border-radius: 0.4rem;
            background-color: rgba(0, 0, 0, 0);
            border: 0.1rem solid transparent;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 0.4rem;
            background-color: rgba(6, 15, 34, 0.2);
            border: 0.1rem solid transparent;
            background-clip: padding-box;
            &:hover {
                background-color: rgba(6, 15, 34, 0.4);
            }
            &:active {
                background-color: rgba(6, 15, 34, 1);
            }
        }
    }
    // to hide scrollbar w/o acctually removing it ...
    &.hidden {
        scrollbar-color: rgba(6, 15, 34, 0) rgba(0, 0, 0, 0) !important; //firefox
        &::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0) !important;
        }
        &::-webkit-scrollbar-thumb {
            background-color: rgba(6, 15, 34, 0) !important;
        }
    }
}
