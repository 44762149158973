@charset "UTF-8";
/*
    .example-el {
        padding: 1rem 3rem;
        margin-bottom: 1rem;

        @include flex-col-center-all(); //  < 560px is flex column 

        @include mq('phablet') {

            @include flex-row-nowrap-center-all(); //  > 560px is flex row 
        }
*/
:root {
  --vivid-sky-blue: hsl(194, 100%, 60%);
  --vivid-sky-blue-h: 194;
  --vivid-sky-blue-s: 100%;
  --vivid-sky-blue-l: 60%;
  --caribbean-green: hsl(163, 84%, 43%);
  --caribbean-green-h: 163;
  --caribbean-green-s: 84%;
  --caribbean-green-l: 43%;
  --rich-black-fogra: hsl(221, 70%, 8%);
  --rich-black-fogra-h: 221;
  --rich-black-fogra-s: 70%;
  --rich-black-fogra-l: 8%;
  --charcoal: hsl(215, 12%, 28%);
  --white: hsl(0, 0%, 99%);
  --page-white: hsl(0, 0%, 95%);
  --grey-1: hsl(219, 14%, 20%);
  --grey-1-h: 219;
  --grey-1-s: 14%;
  --grey-1-l: 20%;
  --grey-2: hsl(218, 14%, 23%);
  --grey-3: hsl(217, 13%, 32%);
  --grey-4: hsl(220, 15%, 43%);
  --primary-white-text: hsl(0, 0%, 99%);
  --secondary-white-text: hsla(0, 0%, 78%, 0.8);
  --primary-black-text: hsl(0, 0%, 1%);
  --secondary-black-text: hsla(221, 70%, 8%, 0.7);
  --primary-grey-text: rgb(126, 129, 136);
  --blue-1: hsl(198, 84%, 36%);
  --blue-2: hsl(198, 80%, 75%);
  --new-status-red: hsl(340, 100%, 50%);
  --new-status-yellow: hsl(41, 100%, 50%);
  --new-status-orange: hsl(27, 100%, 50%);
  --warning-orange: hsl(32, 100%, 50%);
  --warning-orange-h: 32;
  --warning-orange-s: 100%;
  --warning-orange-l: 50%;
  --error-red: hsl(0, 100%, 60%);
  --error-red-h: 0;
  --error-red-s: 100%;
  --error-red-l: 60%;
  --sepia-1: hsl(36, 76%, 74%);
  --sepia-1-h: 36;
  --sepia-1-s: 76%;
  --sepia-1-l: 74%;
  --sepia-nav: hsl(36, 100%, 78%);
  --sepia-2: hsl(36, 100%, 85%);
  --sepia-3: hsl(36, 100%, 90%);
  --sepia-4: hsl(36, 100%, 95%);
  --light-grey-1: hsl(0, 0%, 87%);
  --light-grey-1-h: 0;
  --light-grey-1-s: 0%;
  --light-grey-1-l: 87%;
  --light-grey-2: hsl(0, 0%, 91%);
  --sepia-icon: hsl(37, 70%, 18%);
  --chat-textarea-height: 6.6rem;
  --mat-ripple-color: hsla(0, 0%, 100%, 0.1);
}

body[data-theme=theme-dark] {
  --color-primary-text: var(--primary-white-text);
  --color-secondary-text: var(--secondary-white-text);
  --color-secondary-text-h: 0;
  --color-secondary-text-s: 0%;
  --color-secondary-text-l: 78%;
  --color-contrast-text: var(--primary-black-text);
  --color-sky-blue-text: var(--vivid-sky-blue);
  --color-primary-card: var(--blue-1);
  --color-secondary-card: var(--grey-4);
  --color-disabled-card: hsl(198, 84%, 16%);
  --color-input-background: hsl(219, 8%, 36%);
  --color-toast-background: var(--rich-black-fogra);
  --conference-logo-filter: brightness(167%);
  --color-page-background: var(--charcoal);
  --color-page-background-h: 215;
  --color-page-background-s: 12%;
  --color-page-background-l: 28%;
  --color-primary-container: var(--grey-2);
  --color-primary-container-h: 218;
  --color-primary-container-s: 14%;
  --color-primary-container-l: 23%;
  --color-secondary-container: var(--grey-3);
  --color-alternate-container: var(--grey-2);
  --color-alternate-container-h: 218;
  --color-alternate-container-s: 14%;
  --color-alternate-container-l: 23%;
  --color-dark-container: var(--grey-1);
  --color-mini-modal: var(--grey-4);
  --color-primary-icon: var(--primary-grey-text);
  --color-primary-navbar: var(--grey-2);
  --color-logo-navbar: var(--vivid-sky-blue);
  --sidebar-background: hsl(212, 10%, 25%);
  --sidebar-background-h: 212;
  --sidebar-background-s: 10%;
  --sidebar-background-l: 25%;
  --mat-ripple-color: hsla(0, 0%, 100%, 0.1);
  --mat-expansion-panel-bg: var(--color-primary-container);
  --mat-expansion-panel-bg-hov: rgba(255,255,255,0.1);
  --status-grey2: rgb(211, 211, 211);
  --status-yellow2: rgb(236, 218, 6);
  --red: rgb(214, 3, 3);
}

body[data-theme=theme-light],
body[data-theme=theme-reader] {
  --color-primary-text: var(--primary-black-text);
  --color-secondary-text: var(--secondary-black-text);
  --color-secondary-text-h: 221;
  --color-secondary-text-s: 70%;
  --color-secondary-text-l: 8%;
  --color-contrast-text: var(--primary-white-text);
  --color-sky-blue-text: hsl(194, 100%, 40%);
  --color-primary-card: var(--blue-2);
  --color-secondary-card: var(--white);
  --color-disabled-card: hsl(198, 80%, 40%);
  --color-input-background: var(--white);
  --color-toast-background: var(--white);
  --conference-logo-filter: none;
  --mat-ripple-color: hsla(221, 70%, 8%, 0.1);
  --status-grey2: rgb(162, 162, 162);
  --status-yellow2: rgb(208, 192, 1);
  --red: red;
}

body[data-theme=theme-reader] {
  --color-page-background: var(--sepia-3);
  --color-page-background-h: 36;
  --color-page-background-s: 100%;
  --color-page-background-l: 90%;
  --color-primary-container: var(--sepia-4);
  --color-primary-container-h: 36;
  --color-primary-container-s: 100%;
  --color-primary-container-l: 95%;
  --color-secondary-container: var(--sepia-2);
  --color-alternate-container: var(--sepia-2);
  --color-alternate-container-h: 36;
  --color-alternate-container-s: 100%;
  --color-alternate-container-l: 85%;
  --color-dark-container: var(--sepia-1);
  --color-mini-modal: var(--sepia-4);
  --color-primary-icon: var(--sepia-icon);
  --color-primary-navbar: var(--sepia-nav);
  --color-logo-navbar: var(--sepia-icon);
  --sidebar-background: hsl(30, 100%, 97%);
  --sidebar-background-h: 30;
  --sidebar-background-s: 100%;
  --sidebar-background-l: 97%;
  --mat-expansion-panel-bg: var(--color-primary-container);
  --mat-expansion-panel-bg-hov: rgba(255,255,255,0.4);
}

body[data-theme=theme-light] {
  --color-page-background: var(--page-white);
  --color-page-background-h: 0;
  --color-page-background-s: 0%;
  --color-page-background-l: 95%;
  --color-primary-container: var(--white);
  --color-primary-container-h: 0;
  --color-primary-container-s: 0%;
  --color-primary-container-l: 99%;
  --color-secondary-container: var(--light-grey-2);
  --color-alternate-container: var(--light-grey-2);
  --color-alternate-container-h: 0;
  --color-alternate-container-s: 0%;
  --color-alternate-container-l: 91%;
  --color-dark-container: var(--light-grey-1);
  --color-mini-modal: var(--white);
  --color-primary-icon: var(--primary-black-text);
  --color-primary-navbar: var(--white);
  --color-logo-navbar: var(--rich-black-fogra);
  --sidebar-background: hsl(0, 0%, 80%);
  --sidebar-background-h: 0;
  --sidebar-background-s: 0%;
  --sidebar-background-l: 100%;
  --mat-expansion-panel-bg: var(--color-secondary-container);
  --mat-expansion-panel-bg-hov: rgba(255,255,255,0.9);
}

/* 
    💡 this is our SCSS Index, any shared .scss files should be imported here, 
    so now we only need to @import this index and our components will recieve all files above. 

    ex: @import '../../css/index.scss'; 
*/
/* 💡 This stylesheet is for the app.component.html only*/
:root {
  --sidebar-width: 35rem;
  --navbar-width: 5rem;
  --mobile-navbar-height: 5rem;
}

html,
body {
  height: 100%;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media only screen and (min-width: 560px) {
  .page-wrapper {
    flex-direction: row;
  }
}
.page-wrapper .page-overlay {
  display: flex;
  height: var(--mobile-navbar-height);
}
@media only screen and (min-width: 560px) {
  .page-wrapper .page-overlay {
    height: 100%;
    width: var(--navbar-width);
    min-width: var(--navbar-width);
  }
}
.page-wrapper .page-overlay .navbar {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--mobile-navbar-height);
  background-color: var(--color-primary-navbar);
  z-index: 7000;
  flex-direction: row;
  justify-content: space-between;
}
.page-wrapper .page-overlay .navbar.modal-open {
  z-index: 1;
}
@media only screen and (min-width: 560px) {
  .page-wrapper .page-overlay .navbar.modal-open {
    transition: z-index 0s 0.5s;
    z-index: 6000;
  }
}
@media only screen and (min-width: 560px) {
  .page-wrapper .page-overlay .navbar {
    padding-top: 1.5rem;
    width: var(--navbar-width);
    height: 100%;
    flex-direction: column;
  }
}
.page-wrapper .page-overlay .sidebar {
  position: fixed;
  height: calc(100% - var(--mobile-navbar-height));
  box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.15);
  z-index: 6000;
  display: block;
  top: var(--mobile-navbar-height);
  left: -100%;
  width: 100%;
}
@media only screen and (min-width: 560px) {
  .page-wrapper .page-overlay .sidebar {
    height: 100%;
    top: 0;
    left: calc(-1 * var(--sidebar-width));
    width: var(--sidebar-width);
    transition: box-shadow 0.4s ease-out, left 0.4s ease-out;
  }
}
.page-wrapper .page-overlay .sidebar.side-bar-show {
  left: 0;
  transition: box-shadow 0.4s ease-out, left 0.4s ease-out;
}
@media only screen and (min-width: 560px) {
  .page-wrapper .page-overlay .sidebar.side-bar-show {
    left: var(--navbar-width);
  }
}
.page-wrapper .main-content {
  width: 100%;
  height: calc(100% - (var(--mobile-navbar-height) * 2));
  right: 0;
  top: calc(var(--mobile-navbar-height) * 2);
  transition: 0.4s ease-out;
  position: absolute;
}
@media only screen and (min-width: 560px) {
  .page-wrapper .main-content {
    position: initial;
    height: 100%;
    width: calc(100% - var(--navbar-width));
  }
}
.page-wrapper .main-content.mobile-sidebar {
  right: -100vw;
  overflow: hidden;
  position: fixed;
}
@media only screen and (min-width: 560px) {
  .page-wrapper .main-content.mobile-sidebar {
    right: initial;
    top: initial;
    overflow: initial;
    position: initial;
  }
}
@media only screen and (min-width: 560px) {
  .page-wrapper .main-content.in-channel {
    top: 5rem;
    left: 5rem;
    height: calc(100% - 5rem);
    position: absolute;
    width: calc(100% - 5rem);
  }
}
@media only screen and (min-width: 900px) {
  .page-wrapper .main-content.in-channel {
    position: initial;
    height: 100%;
    width: 100%;
  }
}
.page-wrapper .main-content .hidden-overlay {
  height: 100%;
  width: 100%;
  top: 0;
  position: fixed;
  z-index: 1047;
  background: transparent;
  pointer-events: none;
}
.page-wrapper .main-content .documentPage {
  margin-left: auto;
  margin-right: auto;
  max-width: 160rem;
}
.page-wrapper .main-content .documentPage .headerInfo h2 {
  font-size: 1.5rem;
  margin: 0 1.25rem 1rem 0;
  text-align: right;
}